/**======================================================================
=========================================================================
Template Name: Able Pro - Bootstrap Admin Template
Author: Phoenixcoded
Support: https://phoenixcoded.authordesk.app
File: style.css
=========================================================================
=================================================================================== */
body {
  font-feature-settings: "salt";
}

h1,
h2 {
  font-weight: 700;
}

/* $btn-border-radius: 12px;
$btn-border-radius-sm: 8px;
$btn-border-radius-lg: 14px; */
:root {
  --bs-body-bg: #f8f9fa;
  --bs-body-bg-rgb: 248, 249, 250;
  --pc-heading-color: #1d2630;
  --pc-active-background: #f3f5f7;
  --pc-sidebar-background: transparent;
  --pc-sidebar-color: #5b6b79;
  --pc-sidebar-color-rgb: 91, 107, 121;
  --pc-sidebar-active-color: #0d6efd;
  --pc-sidebar-shadow: none;
  --pc-sidebar-caption-color: #3e4853;
  --pc-sidebar-border: 1px dashed #bec8d0;
  --pc-sidebar-user-background: #f3f5f7;
  --pc-header-background: rgba(var(--bs-body-bg-rgb), 0.7);
  --pc-header-color: #5b6b79;
  --pc-header-shadow: none;
  --pc-card-box-shadow: none;
}

[data-pc-theme_contrast=true] {
  --bs-body-bg: #ffffff;
  --pc-sidebar-background: transparent;
  --pc-sidebar-active-color: #0d6efd;
  --pc-sidebar-shadow: 1px 0 3px 0px #dbe0e5;
  --pc-sidebar-border: none;
  --pc-card-box-shadow: 0px 8px 24px rgba(27, 46, 94, 0.08);
}

[data-pc-preset=preset-1] {
  --pc-sidebar-active-color: #4680ff;
  --bs-blue: #4680ff;
  --bs-primary: #4680ff;
  --bs-primary-rgb: 70, 128, 255;
  --bs-primary-light: #edf2ff;
  --bs-link-color: #4680ff;
  --bs-link-color-rgb: 70, 128, 255;
  --bs-link-hover-color: #3866cc;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
}
[data-pc-preset=preset-1] .bg-light-primary {
  background: #edf2ff;
  color: #4680ff;
}
[data-pc-preset=preset-1] .link-primary {
  color: #4680ff !important;
}
[data-pc-preset=preset-1] .link-primary:hover, [data-pc-preset=preset-1] .link-primary:focus {
  color: #3866cc !important;
}
[data-pc-preset=preset-1] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #4680ff;
  --bs-btn-border-color: #4680ff;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #3c6dd9;
  --bs-btn-hover-border-color: #3866cc;
  --bs-btn-focus-shadow-rgb: 98, 147, 255;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #3866cc;
  --bs-btn-active-border-color: #3560bf;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #4680ff;
  --bs-btn-disabled-border-color: #4680ff;
}
[data-pc-preset=preset-1] .btn-link {
  --bs-btn-color: #4680ff;
  --bs-btn-hover-color: #3866cc;
  --bs-btn-active-color: #3866cc;
}
[data-pc-preset=preset-1] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(70, 128, 255, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-1] .accordion {
  --bs-accordion-btn-focus-border-color: #4680ff;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(70, 128, 255, 0.25);
  --bs-accordion-active-color: #4680ff;
  --bs-accordion-active-bg: #edf2ff;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%234680ff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .alert-primary {
  --bs-alert-color: #2a4d99;
  --bs-alert-bg: #dae6ff;
  --bs-alert-border-color: #c8d9ff;
  --bs-alert-link-color: #223e7a;
}
[data-pc-preset=preset-1] .list-group {
  --bs-list-group-active-bg: #4680ff;
  --bs-list-group-active-border-color: #4680ff;
}
[data-pc-preset=preset-1] .list-group-item-primary {
  color: #2a4d99;
  background-color: #dae6ff;
}
[data-pc-preset=preset-1] .nav {
  --bs-nav-link-hover-color: #3866cc;
}
[data-pc-preset=preset-1] .nav-pills {
  --bs-nav-pills-link-active-bg: #4680ff;
}
[data-pc-preset=preset-1] .pagination {
  --bs-pagination-hover-color: #3866cc;
  --bs-pagination-focus-color: #3866cc;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(70, 128, 255, 0.25);
  --bs-pagination-active-bg: #4680ff;
  --bs-pagination-active-border-color: #4680ff;
}
[data-pc-preset=preset-1] .progress {
  --bs-progress-bar-bg: #4680ff;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-primary:checked {
  border-color: #4680ff;
  background-color: #4680ff;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked {
  border-color: #edf2ff;
  background-color: #edf2ff;
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%234680ff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%234680ff'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(70, 128, 255, 0.25);
  border-color: #4680ff;
}
[data-pc-preset=preset-1] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%234680ff'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-1] .btn-light-primary {
  background: #edf2ff;
  color: #4680ff;
  border-color: #edf2ff;
}
[data-pc-preset=preset-1] .btn-light-primary .material-icons-two-tone {
  background-color: #4680ff;
}
[data-pc-preset=preset-1] .btn-light-primary:hover {
  background: #4680ff;
  color: #fff;
  border-color: #4680ff;
}
[data-pc-preset=preset-1] .btn-light-primary.focus, [data-pc-preset=preset-1] .btn-light-primary:focus {
  background: #4680ff;
  color: #fff;
  border-color: #4680ff;
}
[data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-light-primary.dropdown-toggle {
  background: #4680ff;
  color: #fff;
  border-color: #4680ff;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-light-primary {
  background: #4680ff;
  color: #fff;
  border-color: #4680ff;
}
[data-pc-preset=preset-1] .btn-link-primary {
  background: transparent;
  color: #4680ff;
  border-color: transparent;
}
[data-pc-preset=preset-1] .btn-link-primary .material-icons-two-tone {
  background-color: #4680ff;
}
[data-pc-preset=preset-1] .btn-link-primary:hover {
  background: #edf2ff;
  color: #4680ff;
  border-color: #edf2ff;
}
[data-pc-preset=preset-1] .btn-link-primary.focus, [data-pc-preset=preset-1] .btn-link-primary:focus {
  background: #edf2ff;
  color: #4680ff;
  border-color: #edf2ff;
}
[data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-1] .btn-link-primary.dropdown-toggle {
  background: #edf2ff;
  color: #4680ff;
  border-color: #edf2ff;
}
[data-pc-preset=preset-1] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-1] .btn-check:checked + .btn-link-primary {
  background: #edf2ff;
  color: #4680ff;
  border-color: #edf2ff;
}

[data-pc-preset=preset-2] {
  --pc-sidebar-active-color: #6610f2;
  --bs-blue: #6610f2;
  --bs-primary: #6610f2;
  --bs-primary-rgb: 102, 16, 242;
  --bs-primary-light: #f0e7fe;
  --bs-link-color: #6610f2;
  --bs-link-color-rgb: 102, 16, 242;
  --bs-link-hover-color: #520dc2;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
}
[data-pc-preset=preset-2] .bg-light-primary {
  background: #f0e7fe;
  color: #6610f2;
}
[data-pc-preset=preset-2] .link-primary {
  color: #6610f2 !important;
}
[data-pc-preset=preset-2] .link-primary:hover, [data-pc-preset=preset-2] .link-primary:focus {
  color: #520dc2 !important;
}
[data-pc-preset=preset-2] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #6610f2;
  --bs-btn-border-color: #6610f2;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #570ece;
  --bs-btn-hover-border-color: #520dc2;
  --bs-btn-focus-shadow-rgb: 125, 52, 244;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #520dc2;
  --bs-btn-active-border-color: #4d0cb6;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #6610f2;
  --bs-btn-disabled-border-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-link {
  --bs-btn-color: #6610f2;
  --bs-btn-hover-color: #520dc2;
  --bs-btn-active-color: #520dc2;
}
[data-pc-preset=preset-2] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(102, 16, 242, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-2] .accordion {
  --bs-accordion-btn-focus-border-color: #6610f2;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.25);
  --bs-accordion-active-color: #6610f2;
  --bs-accordion-active-bg: #f0e7fe;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236610f2'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .alert-primary {
  --bs-alert-color: #3d0a91;
  --bs-alert-bg: #e0cffc;
  --bs-alert-border-color: #d1b7fb;
  --bs-alert-link-color: #310874;
}
[data-pc-preset=preset-2] .list-group {
  --bs-list-group-active-bg: #6610f2;
  --bs-list-group-active-border-color: #6610f2;
}
[data-pc-preset=preset-2] .list-group-item-primary {
  color: #3d0a91;
  background-color: #e0cffc;
}
[data-pc-preset=preset-2] .nav {
  --bs-nav-link-hover-color: #520dc2;
}
[data-pc-preset=preset-2] .nav-pills {
  --bs-nav-pills-link-active-bg: #6610f2;
}
[data-pc-preset=preset-2] .pagination {
  --bs-pagination-hover-color: #520dc2;
  --bs-pagination-focus-color: #520dc2;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.25);
  --bs-pagination-active-bg: #6610f2;
  --bs-pagination-active-border-color: #6610f2;
}
[data-pc-preset=preset-2] .progress {
  --bs-progress-bar-bg: #6610f2;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-primary:checked {
  border-color: #6610f2;
  background-color: #6610f2;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked {
  border-color: #f0e7fe;
  background-color: #f0e7fe;
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%236610f2' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%236610f2'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-2] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(102, 16, 242, 0.25);
  border-color: #6610f2;
}
[data-pc-preset=preset-2] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%236610f2'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-2] .btn-light-primary {
  background: #f0e7fe;
  color: #6610f2;
  border-color: #f0e7fe;
}
[data-pc-preset=preset-2] .btn-light-primary .material-icons-two-tone {
  background-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-light-primary:hover {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-light-primary.focus, [data-pc-preset=preset-2] .btn-light-primary:focus {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-2] .btn-light-primary.dropdown-toggle {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-2] .btn-check:checked + .btn-light-primary {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-link-primary {
  background: transparent;
  color: #6610f2;
  border-color: transparent;
}
[data-pc-preset=preset-2] .btn-link-primary .material-icons-two-tone {
  background-color: #6610f2;
}
[data-pc-preset=preset-2] .btn-link-primary:hover {
  background: #f0e7fe;
  color: #6610f2;
  border-color: #f0e7fe;
}
[data-pc-preset=preset-2] .btn-link-primary.focus, [data-pc-preset=preset-2] .btn-link-primary:focus {
  background: #f0e7fe;
  color: #6610f2;
  border-color: #f0e7fe;
}
[data-pc-preset=preset-2] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-2] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-2] .btn-link-primary.dropdown-toggle {
  background: #f0e7fe;
  color: #6610f2;
  border-color: #f0e7fe;
}
[data-pc-preset=preset-2] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-2] .btn-check:checked + .btn-link-primary {
  background: #f0e7fe;
  color: #6610f2;
  border-color: #f0e7fe;
}

[data-pc-preset=preset-3] {
  --pc-sidebar-active-color: #673ab7;
  --bs-blue: #673ab7;
  --bs-primary: #673ab7;
  --bs-primary-rgb: 103, 58, 183;
  --bs-primary-light: #f0ebf8;
  --bs-link-color: #673ab7;
  --bs-link-color-rgb: 103, 58, 183;
  --bs-link-hover-color: #522e92;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
}
[data-pc-preset=preset-3] .bg-light-primary {
  background: #f0ebf8;
  color: #673ab7;
}
[data-pc-preset=preset-3] .link-primary {
  color: #673ab7 !important;
}
[data-pc-preset=preset-3] .link-primary:hover, [data-pc-preset=preset-3] .link-primary:focus {
  color: #522e92 !important;
}
[data-pc-preset=preset-3] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #673ab7;
  --bs-btn-border-color: #673ab7;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #58319c;
  --bs-btn-hover-border-color: #522e92;
  --bs-btn-focus-shadow-rgb: 126, 88, 194;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #522e92;
  --bs-btn-active-border-color: #4d2c89;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #673ab7;
  --bs-btn-disabled-border-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-link {
  --bs-btn-color: #673ab7;
  --bs-btn-hover-color: #522e92;
  --bs-btn-active-color: #522e92;
}
[data-pc-preset=preset-3] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(103, 58, 183, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-3] .accordion {
  --bs-accordion-btn-focus-border-color: #673ab7;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(103, 58, 183, 0.25);
  --bs-accordion-active-color: #673ab7;
  --bs-accordion-active-bg: #f0ebf8;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23673ab7'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .alert-primary {
  --bs-alert-color: #3e236e;
  --bs-alert-bg: #e1d8f1;
  --bs-alert-border-color: #d1c4e9;
  --bs-alert-link-color: #321c58;
}
[data-pc-preset=preset-3] .list-group {
  --bs-list-group-active-bg: #673ab7;
  --bs-list-group-active-border-color: #673ab7;
}
[data-pc-preset=preset-3] .list-group-item-primary {
  color: #3e236e;
  background-color: #e1d8f1;
}
[data-pc-preset=preset-3] .nav {
  --bs-nav-link-hover-color: #522e92;
}
[data-pc-preset=preset-3] .nav-pills {
  --bs-nav-pills-link-active-bg: #673ab7;
}
[data-pc-preset=preset-3] .pagination {
  --bs-pagination-hover-color: #522e92;
  --bs-pagination-focus-color: #522e92;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(103, 58, 183, 0.25);
  --bs-pagination-active-bg: #673ab7;
  --bs-pagination-active-border-color: #673ab7;
}
[data-pc-preset=preset-3] .progress {
  --bs-progress-bar-bg: #673ab7;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-primary:checked {
  border-color: #673ab7;
  background-color: #673ab7;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked {
  border-color: #f0ebf8;
  background-color: #f0ebf8;
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23673ab7' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23673ab7'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-3] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(103, 58, 183, 0.25);
  border-color: #673ab7;
}
[data-pc-preset=preset-3] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23673ab7'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-3] .btn-light-primary {
  background: #f0ebf8;
  color: #673ab7;
  border-color: #f0ebf8;
}
[data-pc-preset=preset-3] .btn-light-primary .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-light-primary:hover {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-light-primary.focus, [data-pc-preset=preset-3] .btn-light-primary:focus {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-3] .btn-light-primary.dropdown-toggle {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-3] .btn-check:checked + .btn-light-primary {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-link-primary {
  background: transparent;
  color: #673ab7;
  border-color: transparent;
}
[data-pc-preset=preset-3] .btn-link-primary .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-preset=preset-3] .btn-link-primary:hover {
  background: #f0ebf8;
  color: #673ab7;
  border-color: #f0ebf8;
}
[data-pc-preset=preset-3] .btn-link-primary.focus, [data-pc-preset=preset-3] .btn-link-primary:focus {
  background: #f0ebf8;
  color: #673ab7;
  border-color: #f0ebf8;
}
[data-pc-preset=preset-3] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-3] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-3] .btn-link-primary.dropdown-toggle {
  background: #f0ebf8;
  color: #673ab7;
  border-color: #f0ebf8;
}
[data-pc-preset=preset-3] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-3] .btn-check:checked + .btn-link-primary {
  background: #f0ebf8;
  color: #673ab7;
  border-color: #f0ebf8;
}

[data-pc-preset=preset-4] {
  --pc-sidebar-active-color: #e83e8c;
  --bs-blue: #e83e8c;
  --bs-primary: #e83e8c;
  --bs-primary-rgb: 232, 62, 140;
  --bs-primary-light: #fdecf4;
  --bs-link-color: #e83e8c;
  --bs-link-color-rgb: 232, 62, 140;
  --bs-link-hover-color: #ba3270;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
}
[data-pc-preset=preset-4] .bg-light-primary {
  background: #fdecf4;
  color: #e83e8c;
}
[data-pc-preset=preset-4] .link-primary {
  color: #e83e8c !important;
}
[data-pc-preset=preset-4] .link-primary:hover, [data-pc-preset=preset-4] .link-primary:focus {
  color: #ba3270 !important;
}
[data-pc-preset=preset-4] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #e83e8c;
  --bs-btn-border-color: #e83e8c;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #c53577;
  --bs-btn-hover-border-color: #ba3270;
  --bs-btn-focus-shadow-rgb: 235, 91, 157;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ba3270;
  --bs-btn-active-border-color: #ae2f69;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #e83e8c;
  --bs-btn-disabled-border-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-link {
  --bs-btn-color: #e83e8c;
  --bs-btn-hover-color: #ba3270;
  --bs-btn-active-color: #ba3270;
}
[data-pc-preset=preset-4] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(232, 62, 140, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-4] .accordion {
  --bs-accordion-btn-focus-border-color: #e83e8c;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.25);
  --bs-accordion-active-color: #e83e8c;
  --bs-accordion-active-bg: #fdecf4;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e83e8c'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .alert-primary {
  --bs-alert-color: #8b2554;
  --bs-alert-bg: #fad8e8;
  --bs-alert-border-color: #f8c5dd;
  --bs-alert-link-color: #6f1e43;
}
[data-pc-preset=preset-4] .list-group {
  --bs-list-group-active-bg: #e83e8c;
  --bs-list-group-active-border-color: #e83e8c;
}
[data-pc-preset=preset-4] .list-group-item-primary {
  color: #8b2554;
  background-color: #fad8e8;
}
[data-pc-preset=preset-4] .nav {
  --bs-nav-link-hover-color: #ba3270;
}
[data-pc-preset=preset-4] .nav-pills {
  --bs-nav-pills-link-active-bg: #e83e8c;
}
[data-pc-preset=preset-4] .pagination {
  --bs-pagination-hover-color: #ba3270;
  --bs-pagination-focus-color: #ba3270;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.25);
  --bs-pagination-active-bg: #e83e8c;
  --bs-pagination-active-border-color: #e83e8c;
}
[data-pc-preset=preset-4] .progress {
  --bs-progress-bar-bg: #e83e8c;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-primary:checked {
  border-color: #e83e8c;
  background-color: #e83e8c;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked {
  border-color: #fdecf4;
  background-color: #fdecf4;
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23e83e8c' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23e83e8c'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-4] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(232, 62, 140, 0.25);
  border-color: #e83e8c;
}
[data-pc-preset=preset-4] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23e83e8c'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-4] .btn-light-primary {
  background: #fdecf4;
  color: #e83e8c;
  border-color: #fdecf4;
}
[data-pc-preset=preset-4] .btn-light-primary .material-icons-two-tone {
  background-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-light-primary:hover {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-light-primary.focus, [data-pc-preset=preset-4] .btn-light-primary:focus {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-4] .btn-light-primary.dropdown-toggle {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-4] .btn-check:checked + .btn-light-primary {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-link-primary {
  background: transparent;
  color: #e83e8c;
  border-color: transparent;
}
[data-pc-preset=preset-4] .btn-link-primary .material-icons-two-tone {
  background-color: #e83e8c;
}
[data-pc-preset=preset-4] .btn-link-primary:hover {
  background: #fdecf4;
  color: #e83e8c;
  border-color: #fdecf4;
}
[data-pc-preset=preset-4] .btn-link-primary.focus, [data-pc-preset=preset-4] .btn-link-primary:focus {
  background: #fdecf4;
  color: #e83e8c;
  border-color: #fdecf4;
}
[data-pc-preset=preset-4] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-4] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-4] .btn-link-primary.dropdown-toggle {
  background: #fdecf4;
  color: #e83e8c;
  border-color: #fdecf4;
}
[data-pc-preset=preset-4] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-4] .btn-check:checked + .btn-link-primary {
  background: #fdecf4;
  color: #e83e8c;
  border-color: #fdecf4;
}

[data-pc-preset=preset-5] {
  --pc-sidebar-active-color: #dc2626;
  --bs-blue: #dc2626;
  --bs-primary: #dc2626;
  --bs-primary-rgb: 220, 38, 38;
  --bs-primary-light: #fce9e9;
  --bs-link-color: #dc2626;
  --bs-link-color-rgb: 220, 38, 38;
  --bs-link-hover-color: #b01e1e;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
}
[data-pc-preset=preset-5] .bg-light-primary {
  background: #fce9e9;
  color: #dc2626;
}
[data-pc-preset=preset-5] .link-primary {
  color: #dc2626 !important;
}
[data-pc-preset=preset-5] .link-primary:hover, [data-pc-preset=preset-5] .link-primary:focus {
  color: #b01e1e !important;
}
[data-pc-preset=preset-5] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #dc2626;
  --bs-btn-border-color: #dc2626;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #bb2020;
  --bs-btn-hover-border-color: #b01e1e;
  --bs-btn-focus-shadow-rgb: 225, 71, 71;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #b01e1e;
  --bs-btn-active-border-color: #a51d1d;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #dc2626;
  --bs-btn-disabled-border-color: #dc2626;
}
[data-pc-preset=preset-5] .btn-link {
  --bs-btn-color: #dc2626;
  --bs-btn-hover-color: #b01e1e;
  --bs-btn-active-color: #b01e1e;
}
[data-pc-preset=preset-5] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(220, 38, 38, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-5] .accordion {
  --bs-accordion-btn-focus-border-color: #dc2626;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(220, 38, 38, 0.25);
  --bs-accordion-active-color: #dc2626;
  --bs-accordion-active-bg: #fce9e9;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23dc2626'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .alert-primary {
  --bs-alert-color: #841717;
  --bs-alert-bg: #f8d4d4;
  --bs-alert-border-color: #f5bebe;
  --bs-alert-link-color: #6a1212;
}
[data-pc-preset=preset-5] .list-group {
  --bs-list-group-active-bg: #dc2626;
  --bs-list-group-active-border-color: #dc2626;
}
[data-pc-preset=preset-5] .list-group-item-primary {
  color: #841717;
  background-color: #f8d4d4;
}
[data-pc-preset=preset-5] .nav {
  --bs-nav-link-hover-color: #b01e1e;
}
[data-pc-preset=preset-5] .nav-pills {
  --bs-nav-pills-link-active-bg: #dc2626;
}
[data-pc-preset=preset-5] .pagination {
  --bs-pagination-hover-color: #b01e1e;
  --bs-pagination-focus-color: #b01e1e;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(220, 38, 38, 0.25);
  --bs-pagination-active-bg: #dc2626;
  --bs-pagination-active-border-color: #dc2626;
}
[data-pc-preset=preset-5] .progress {
  --bs-progress-bar-bg: #dc2626;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-primary:checked {
  border-color: #dc2626;
  background-color: #dc2626;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked {
  border-color: #fce9e9;
  background-color: #fce9e9;
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23dc2626' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23dc2626'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-5] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(220, 38, 38, 0.25);
  border-color: #dc2626;
}
[data-pc-preset=preset-5] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23dc2626'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-5] .btn-light-primary {
  background: #fce9e9;
  color: #dc2626;
  border-color: #fce9e9;
}
[data-pc-preset=preset-5] .btn-light-primary .material-icons-two-tone {
  background-color: #dc2626;
}
[data-pc-preset=preset-5] .btn-light-primary:hover {
  background: #dc2626;
  color: #fff;
  border-color: #dc2626;
}
[data-pc-preset=preset-5] .btn-light-primary.focus, [data-pc-preset=preset-5] .btn-light-primary:focus {
  background: #dc2626;
  color: #fff;
  border-color: #dc2626;
}
[data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-5] .btn-light-primary.dropdown-toggle {
  background: #dc2626;
  color: #fff;
  border-color: #dc2626;
}
[data-pc-preset=preset-5] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-5] .btn-check:checked + .btn-light-primary {
  background: #dc2626;
  color: #fff;
  border-color: #dc2626;
}
[data-pc-preset=preset-5] .btn-link-primary {
  background: transparent;
  color: #dc2626;
  border-color: transparent;
}
[data-pc-preset=preset-5] .btn-link-primary .material-icons-two-tone {
  background-color: #dc2626;
}
[data-pc-preset=preset-5] .btn-link-primary:hover {
  background: #fce9e9;
  color: #dc2626;
  border-color: #fce9e9;
}
[data-pc-preset=preset-5] .btn-link-primary.focus, [data-pc-preset=preset-5] .btn-link-primary:focus {
  background: #fce9e9;
  color: #dc2626;
  border-color: #fce9e9;
}
[data-pc-preset=preset-5] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-5] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-5] .btn-link-primary.dropdown-toggle {
  background: #fce9e9;
  color: #dc2626;
  border-color: #fce9e9;
}
[data-pc-preset=preset-5] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-5] .btn-check:checked + .btn-link-primary {
  background: #fce9e9;
  color: #dc2626;
  border-color: #fce9e9;
}

[data-pc-preset=preset-6] {
  --pc-sidebar-active-color: #fd7e14;
  --bs-blue: #fd7e14;
  --bs-primary: #fd7e14;
  --bs-primary-rgb: 253, 126, 20;
  --bs-primary-light: #fff2e8;
  --bs-link-color: #fd7e14;
  --bs-link-color-rgb: 253, 126, 20;
  --bs-link-hover-color: #ca6510;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
}
[data-pc-preset=preset-6] .bg-light-primary {
  background: #fff2e8;
  color: #fd7e14;
}
[data-pc-preset=preset-6] .link-primary {
  color: #fd7e14 !important;
}
[data-pc-preset=preset-6] .link-primary:hover, [data-pc-preset=preset-6] .link-primary:focus {
  color: #ca6510 !important;
}
[data-pc-preset=preset-6] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #fd7e14;
  --bs-btn-border-color: #fd7e14;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #d76b11;
  --bs-btn-hover-border-color: #ca6510;
  --bs-btn-focus-shadow-rgb: 253, 145, 55;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #ca6510;
  --bs-btn-active-border-color: #be5f0f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #fd7e14;
  --bs-btn-disabled-border-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-link {
  --bs-btn-color: #fd7e14;
  --bs-btn-hover-color: #ca6510;
  --bs-btn-active-color: #ca6510;
}
[data-pc-preset=preset-6] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(253, 126, 20, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-6] .accordion {
  --bs-accordion-btn-focus-border-color: #fd7e14;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.25);
  --bs-accordion-active-color: #fd7e14;
  --bs-accordion-active-bg: #fff2e8;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fd7e14'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .alert-primary {
  --bs-alert-color: #984c0c;
  --bs-alert-bg: #ffe5d0;
  --bs-alert-border-color: #fed8b9;
  --bs-alert-link-color: #7a3d0a;
}
[data-pc-preset=preset-6] .list-group {
  --bs-list-group-active-bg: #fd7e14;
  --bs-list-group-active-border-color: #fd7e14;
}
[data-pc-preset=preset-6] .list-group-item-primary {
  color: #984c0c;
  background-color: #ffe5d0;
}
[data-pc-preset=preset-6] .nav {
  --bs-nav-link-hover-color: #ca6510;
}
[data-pc-preset=preset-6] .nav-pills {
  --bs-nav-pills-link-active-bg: #fd7e14;
}
[data-pc-preset=preset-6] .pagination {
  --bs-pagination-hover-color: #ca6510;
  --bs-pagination-focus-color: #ca6510;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.25);
  --bs-pagination-active-bg: #fd7e14;
  --bs-pagination-active-border-color: #fd7e14;
}
[data-pc-preset=preset-6] .progress {
  --bs-progress-bar-bg: #fd7e14;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-primary:checked {
  border-color: #fd7e14;
  background-color: #fd7e14;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked {
  border-color: #fff2e8;
  background-color: #fff2e8;
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fd7e14' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fd7e14'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-6] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(253, 126, 20, 0.25);
  border-color: #fd7e14;
}
[data-pc-preset=preset-6] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fd7e14'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-6] .btn-light-primary {
  background: #fff2e8;
  color: #fd7e14;
  border-color: #fff2e8;
}
[data-pc-preset=preset-6] .btn-light-primary .material-icons-two-tone {
  background-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-light-primary:hover {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-light-primary.focus, [data-pc-preset=preset-6] .btn-light-primary:focus {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-6] .btn-light-primary.dropdown-toggle {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-6] .btn-check:checked + .btn-light-primary {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-link-primary {
  background: transparent;
  color: #fd7e14;
  border-color: transparent;
}
[data-pc-preset=preset-6] .btn-link-primary .material-icons-two-tone {
  background-color: #fd7e14;
}
[data-pc-preset=preset-6] .btn-link-primary:hover {
  background: #fff2e8;
  color: #fd7e14;
  border-color: #fff2e8;
}
[data-pc-preset=preset-6] .btn-link-primary.focus, [data-pc-preset=preset-6] .btn-link-primary:focus {
  background: #fff2e8;
  color: #fd7e14;
  border-color: #fff2e8;
}
[data-pc-preset=preset-6] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-6] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-6] .btn-link-primary.dropdown-toggle {
  background: #fff2e8;
  color: #fd7e14;
  border-color: #fff2e8;
}
[data-pc-preset=preset-6] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-6] .btn-check:checked + .btn-link-primary {
  background: #fff2e8;
  color: #fd7e14;
  border-color: #fff2e8;
}

[data-pc-preset=preset-7] {
  --pc-sidebar-active-color: #e58a00;
  --bs-blue: #e58a00;
  --bs-primary: #e58a00;
  --bs-primary-rgb: 229, 138, 0;
  --bs-primary-light: #fcf3e6;
  --bs-link-color: #e58a00;
  --bs-link-color-rgb: 229, 138, 0;
  --bs-link-hover-color: #b76e00;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
}
[data-pc-preset=preset-7] .bg-light-primary {
  background: #fcf3e6;
  color: #e58a00;
}
[data-pc-preset=preset-7] .link-primary {
  color: #e58a00 !important;
}
[data-pc-preset=preset-7] .link-primary:hover, [data-pc-preset=preset-7] .link-primary:focus {
  color: #b76e00 !important;
}
[data-pc-preset=preset-7] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #e58a00;
  --bs-btn-border-color: #e58a00;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #c37500;
  --bs-btn-hover-border-color: #b76e00;
  --bs-btn-focus-shadow-rgb: 233, 156, 38;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #b76e00;
  --bs-btn-active-border-color: #ac6800;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #e58a00;
  --bs-btn-disabled-border-color: #e58a00;
}
[data-pc-preset=preset-7] .btn-link {
  --bs-btn-color: #e58a00;
  --bs-btn-hover-color: #b76e00;
  --bs-btn-active-color: #b76e00;
}
[data-pc-preset=preset-7] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(229, 138, 0, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-7] .accordion {
  --bs-accordion-btn-focus-border-color: #e58a00;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(229, 138, 0, 0.25);
  --bs-accordion-active-color: #e58a00;
  --bs-accordion-active-bg: #fcf3e6;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23e58a00'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .alert-primary {
  --bs-alert-color: #895300;
  --bs-alert-bg: #fae8cc;
  --bs-alert-border-color: #f7dcb3;
  --bs-alert-link-color: #6e4200;
}
[data-pc-preset=preset-7] .list-group {
  --bs-list-group-active-bg: #e58a00;
  --bs-list-group-active-border-color: #e58a00;
}
[data-pc-preset=preset-7] .list-group-item-primary {
  color: #895300;
  background-color: #fae8cc;
}
[data-pc-preset=preset-7] .nav {
  --bs-nav-link-hover-color: #b76e00;
}
[data-pc-preset=preset-7] .nav-pills {
  --bs-nav-pills-link-active-bg: #e58a00;
}
[data-pc-preset=preset-7] .pagination {
  --bs-pagination-hover-color: #b76e00;
  --bs-pagination-focus-color: #b76e00;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(229, 138, 0, 0.25);
  --bs-pagination-active-bg: #e58a00;
  --bs-pagination-active-border-color: #e58a00;
}
[data-pc-preset=preset-7] .progress {
  --bs-progress-bar-bg: #e58a00;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-primary:checked {
  border-color: #e58a00;
  background-color: #e58a00;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked {
  border-color: #fcf3e6;
  background-color: #fcf3e6;
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23e58a00' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23e58a00'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-7] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(229, 138, 0, 0.25);
  border-color: #e58a00;
}
[data-pc-preset=preset-7] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23e58a00'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-7] .btn-light-primary {
  background: #fcf3e6;
  color: #e58a00;
  border-color: #fcf3e6;
}
[data-pc-preset=preset-7] .btn-light-primary .material-icons-two-tone {
  background-color: #e58a00;
}
[data-pc-preset=preset-7] .btn-light-primary:hover {
  background: #e58a00;
  color: #fff;
  border-color: #e58a00;
}
[data-pc-preset=preset-7] .btn-light-primary.focus, [data-pc-preset=preset-7] .btn-light-primary:focus {
  background: #e58a00;
  color: #fff;
  border-color: #e58a00;
}
[data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-7] .btn-light-primary.dropdown-toggle {
  background: #e58a00;
  color: #fff;
  border-color: #e58a00;
}
[data-pc-preset=preset-7] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-7] .btn-check:checked + .btn-light-primary {
  background: #e58a00;
  color: #fff;
  border-color: #e58a00;
}
[data-pc-preset=preset-7] .btn-link-primary {
  background: transparent;
  color: #e58a00;
  border-color: transparent;
}
[data-pc-preset=preset-7] .btn-link-primary .material-icons-two-tone {
  background-color: #e58a00;
}
[data-pc-preset=preset-7] .btn-link-primary:hover {
  background: #fcf3e6;
  color: #e58a00;
  border-color: #fcf3e6;
}
[data-pc-preset=preset-7] .btn-link-primary.focus, [data-pc-preset=preset-7] .btn-link-primary:focus {
  background: #fcf3e6;
  color: #e58a00;
  border-color: #fcf3e6;
}
[data-pc-preset=preset-7] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-7] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-7] .btn-link-primary.dropdown-toggle {
  background: #fcf3e6;
  color: #e58a00;
  border-color: #fcf3e6;
}
[data-pc-preset=preset-7] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-7] .btn-check:checked + .btn-link-primary {
  background: #fcf3e6;
  color: #e58a00;
  border-color: #fcf3e6;
}

[data-pc-preset=preset-8] {
  --pc-sidebar-active-color: #2ca87f;
  --bs-blue: #2ca87f;
  --bs-primary: #2ca87f;
  --bs-primary-rgb: 44, 168, 127;
  --bs-primary-light: #eaf6f2;
  --bs-link-color: #2ca87f;
  --bs-link-color-rgb: 44, 168, 127;
  --bs-link-hover-color: #238666;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
}
[data-pc-preset=preset-8] .bg-light-primary {
  background: #eaf6f2;
  color: #2ca87f;
}
[data-pc-preset=preset-8] .link-primary {
  color: #2ca87f !important;
}
[data-pc-preset=preset-8] .link-primary:hover, [data-pc-preset=preset-8] .link-primary:focus {
  color: #238666 !important;
}
[data-pc-preset=preset-8] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #2ca87f;
  --bs-btn-border-color: #2ca87f;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #258f6c;
  --bs-btn-hover-border-color: #238666;
  --bs-btn-focus-shadow-rgb: 76, 181, 146;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #238666;
  --bs-btn-active-border-color: #217e5f;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #2ca87f;
  --bs-btn-disabled-border-color: #2ca87f;
}
[data-pc-preset=preset-8] .btn-link {
  --bs-btn-color: #2ca87f;
  --bs-btn-hover-color: #238666;
  --bs-btn-active-color: #238666;
}
[data-pc-preset=preset-8] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(44, 168, 127, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-8] .accordion {
  --bs-accordion-btn-focus-border-color: #2ca87f;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(44, 168, 127, 0.25);
  --bs-accordion-active-color: #2ca87f;
  --bs-accordion-active-bg: #eaf6f2;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232ca87f'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .alert-primary {
  --bs-alert-color: #1a654c;
  --bs-alert-bg: #d5eee5;
  --bs-alert-border-color: #c0e5d9;
  --bs-alert-link-color: #15513d;
}
[data-pc-preset=preset-8] .list-group {
  --bs-list-group-active-bg: #2ca87f;
  --bs-list-group-active-border-color: #2ca87f;
}
[data-pc-preset=preset-8] .list-group-item-primary {
  color: #1a654c;
  background-color: #d5eee5;
}
[data-pc-preset=preset-8] .nav {
  --bs-nav-link-hover-color: #238666;
}
[data-pc-preset=preset-8] .nav-pills {
  --bs-nav-pills-link-active-bg: #2ca87f;
}
[data-pc-preset=preset-8] .pagination {
  --bs-pagination-hover-color: #238666;
  --bs-pagination-focus-color: #238666;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(44, 168, 127, 0.25);
  --bs-pagination-active-bg: #2ca87f;
  --bs-pagination-active-border-color: #2ca87f;
}
[data-pc-preset=preset-8] .progress {
  --bs-progress-bar-bg: #2ca87f;
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-primary:checked {
  border-color: #2ca87f;
  background-color: #2ca87f;
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:checked {
  border-color: #eaf6f2;
  background-color: #eaf6f2;
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%232ca87f' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%232ca87f'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-8] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(44, 168, 127, 0.25);
  border-color: #2ca87f;
}
[data-pc-preset=preset-8] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%232ca87f'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-8] .btn-light-primary {
  background: #eaf6f2;
  color: #2ca87f;
  border-color: #eaf6f2;
}
[data-pc-preset=preset-8] .btn-light-primary .material-icons-two-tone {
  background-color: #2ca87f;
}
[data-pc-preset=preset-8] .btn-light-primary:hover {
  background: #2ca87f;
  color: #fff;
  border-color: #2ca87f;
}
[data-pc-preset=preset-8] .btn-light-primary.focus, [data-pc-preset=preset-8] .btn-light-primary:focus {
  background: #2ca87f;
  color: #fff;
  border-color: #2ca87f;
}
[data-pc-preset=preset-8] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-8] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-8] .btn-light-primary.dropdown-toggle {
  background: #2ca87f;
  color: #fff;
  border-color: #2ca87f;
}
[data-pc-preset=preset-8] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-8] .btn-check:checked + .btn-light-primary {
  background: #2ca87f;
  color: #fff;
  border-color: #2ca87f;
}
[data-pc-preset=preset-8] .btn-link-primary {
  background: transparent;
  color: #2ca87f;
  border-color: transparent;
}
[data-pc-preset=preset-8] .btn-link-primary .material-icons-two-tone {
  background-color: #2ca87f;
}
[data-pc-preset=preset-8] .btn-link-primary:hover {
  background: #eaf6f2;
  color: #2ca87f;
  border-color: #eaf6f2;
}
[data-pc-preset=preset-8] .btn-link-primary.focus, [data-pc-preset=preset-8] .btn-link-primary:focus {
  background: #eaf6f2;
  color: #2ca87f;
  border-color: #eaf6f2;
}
[data-pc-preset=preset-8] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-8] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-8] .btn-link-primary.dropdown-toggle {
  background: #eaf6f2;
  color: #2ca87f;
  border-color: #eaf6f2;
}
[data-pc-preset=preset-8] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-8] .btn-check:checked + .btn-link-primary {
  background: #eaf6f2;
  color: #2ca87f;
  border-color: #eaf6f2;
}

[data-pc-preset=preset-9] {
  --pc-sidebar-active-color: #008080;
  --bs-blue: #008080;
  --bs-primary: #008080;
  --bs-primary-rgb: 0, 128, 128;
  --bs-primary-light: #e6f2f2;
  --bs-link-color: #008080;
  --bs-link-color-rgb: 0, 128, 128;
  --bs-link-hover-color: #006666;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
}
[data-pc-preset=preset-9] .bg-light-primary {
  background: #e6f2f2;
  color: #008080;
}
[data-pc-preset=preset-9] .link-primary {
  color: #008080 !important;
}
[data-pc-preset=preset-9] .link-primary:hover, [data-pc-preset=preset-9] .link-primary:focus {
  color: #006666 !important;
}
[data-pc-preset=preset-9] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #008080;
  --bs-btn-border-color: #008080;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #006d6d;
  --bs-btn-hover-border-color: #006666;
  --bs-btn-focus-shadow-rgb: 38, 147, 147;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #006666;
  --bs-btn-active-border-color: #006060;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #008080;
  --bs-btn-disabled-border-color: #008080;
}
[data-pc-preset=preset-9] .btn-link {
  --bs-btn-color: #008080;
  --bs-btn-hover-color: #006666;
  --bs-btn-active-color: #006666;
}
[data-pc-preset=preset-9] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(0, 128, 128, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-9] .accordion {
  --bs-accordion-btn-focus-border-color: #008080;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(0, 128, 128, 0.25);
  --bs-accordion-active-color: #008080;
  --bs-accordion-active-bg: #e6f2f2;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23008080'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .alert-primary {
  --bs-alert-color: #004d4d;
  --bs-alert-bg: #cce6e6;
  --bs-alert-border-color: #b3d9d9;
  --bs-alert-link-color: #003e3e;
}
[data-pc-preset=preset-9] .list-group {
  --bs-list-group-active-bg: #008080;
  --bs-list-group-active-border-color: #008080;
}
[data-pc-preset=preset-9] .list-group-item-primary {
  color: #004d4d;
  background-color: #cce6e6;
}
[data-pc-preset=preset-9] .nav {
  --bs-nav-link-hover-color: #006666;
}
[data-pc-preset=preset-9] .nav-pills {
  --bs-nav-pills-link-active-bg: #008080;
}
[data-pc-preset=preset-9] .pagination {
  --bs-pagination-hover-color: #006666;
  --bs-pagination-focus-color: #006666;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(0, 128, 128, 0.25);
  --bs-pagination-active-bg: #008080;
  --bs-pagination-active-border-color: #008080;
}
[data-pc-preset=preset-9] .progress {
  --bs-progress-bar-bg: #008080;
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-primary:checked {
  border-color: #008080;
  background-color: #008080;
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:checked {
  border-color: #e6f2f2;
  background-color: #e6f2f2;
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23008080' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23008080'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-9] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(0, 128, 128, 0.25);
  border-color: #008080;
}
[data-pc-preset=preset-9] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23008080'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-9] .btn-light-primary {
  background: #e6f2f2;
  color: #008080;
  border-color: #e6f2f2;
}
[data-pc-preset=preset-9] .btn-light-primary .material-icons-two-tone {
  background-color: #008080;
}
[data-pc-preset=preset-9] .btn-light-primary:hover {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-preset=preset-9] .btn-light-primary.focus, [data-pc-preset=preset-9] .btn-light-primary:focus {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-preset=preset-9] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-9] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-9] .btn-light-primary.dropdown-toggle {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-preset=preset-9] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-9] .btn-check:checked + .btn-light-primary {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-preset=preset-9] .btn-link-primary {
  background: transparent;
  color: #008080;
  border-color: transparent;
}
[data-pc-preset=preset-9] .btn-link-primary .material-icons-two-tone {
  background-color: #008080;
}
[data-pc-preset=preset-9] .btn-link-primary:hover {
  background: #e6f2f2;
  color: #008080;
  border-color: #e6f2f2;
}
[data-pc-preset=preset-9] .btn-link-primary.focus, [data-pc-preset=preset-9] .btn-link-primary:focus {
  background: #e6f2f2;
  color: #008080;
  border-color: #e6f2f2;
}
[data-pc-preset=preset-9] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-9] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-9] .btn-link-primary.dropdown-toggle {
  background: #e6f2f2;
  color: #008080;
  border-color: #e6f2f2;
}
[data-pc-preset=preset-9] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-9] .btn-check:checked + .btn-link-primary {
  background: #e6f2f2;
  color: #008080;
  border-color: #e6f2f2;
}

[data-pc-preset=preset-10] {
  --pc-sidebar-active-color: #3ec9d6;
  --bs-blue: #3ec9d6;
  --bs-primary: #3ec9d6;
  --bs-primary-rgb: 62, 201, 214;
  --bs-primary-light: #ecfafb;
  --bs-link-color: #3ec9d6;
  --bs-link-color-rgb: 62, 201, 214;
  --bs-link-hover-color: #32a1ab;
  --bs-link-hover-color-rgb: to-rgb(shift-color($pc-primary, $link-shade-percentage));
}
[data-pc-preset=preset-10] .bg-light-primary {
  background: #ecfafb;
  color: #3ec9d6;
}
[data-pc-preset=preset-10] .link-primary {
  color: #3ec9d6 !important;
}
[data-pc-preset=preset-10] .link-primary:hover, [data-pc-preset=preset-10] .link-primary:focus {
  color: #32a1ab !important;
}
[data-pc-preset=preset-10] .btn-primary {
  --bs-btn-color: #ffffff;
  --bs-btn-bg: #3ec9d6;
  --bs-btn-border-color: #3ec9d6;
  --bs-btn-hover-color: #ffffff;
  --bs-btn-hover-bg: #35abb6;
  --bs-btn-hover-border-color: #32a1ab;
  --bs-btn-focus-shadow-rgb: 91, 209, 220;
  --bs-btn-active-color: #ffffff;
  --bs-btn-active-bg: #32a1ab;
  --bs-btn-active-border-color: #2f97a1;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: #3ec9d6;
  --bs-btn-disabled-border-color: #3ec9d6;
}
[data-pc-preset=preset-10] .btn-link {
  --bs-btn-color: #3ec9d6;
  --bs-btn-hover-color: #32a1ab;
  --bs-btn-active-color: #32a1ab;
}
[data-pc-preset=preset-10] .text-bg-primary {
  color: #ffffff !important;
  background-color: RGBA(62, 201, 214, var(--bs-bg-opacity, 1)) !important;
}
[data-pc-preset=preset-10] .accordion {
  --bs-accordion-btn-focus-border-color: #3ec9d6;
  --bs-accordion-btn-focus-box-shadow: 0 0 0 0.2rem rgba(62, 201, 214, 0.25);
  --bs-accordion-active-color: #3ec9d6;
  --bs-accordion-active-bg: #ecfafb;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233ec9d6'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-10] .alert-primary {
  --bs-alert-color: #257980;
  --bs-alert-bg: #d8f4f7;
  --bs-alert-border-color: #c5eff3;
  --bs-alert-link-color: #1e6166;
}
[data-pc-preset=preset-10] .list-group {
  --bs-list-group-active-bg: #3ec9d6;
  --bs-list-group-active-border-color: #3ec9d6;
}
[data-pc-preset=preset-10] .list-group-item-primary {
  color: #257980;
  background-color: #d8f4f7;
}
[data-pc-preset=preset-10] .nav {
  --bs-nav-link-hover-color: #32a1ab;
}
[data-pc-preset=preset-10] .nav-pills {
  --bs-nav-pills-link-active-bg: #3ec9d6;
}
[data-pc-preset=preset-10] .pagination {
  --bs-pagination-hover-color: #32a1ab;
  --bs-pagination-focus-color: #32a1ab;
  --bs-pagination-focus-box-shadow: 0 0 0 0.2rem rgba(62, 201, 214, 0.25);
  --bs-pagination-active-bg: #3ec9d6;
  --bs-pagination-active-border-color: #3ec9d6;
}
[data-pc-preset=preset-10] .progress {
  --bs-progress-bar-bg: #3ec9d6;
}
[data-pc-preset=preset-10] .form-check .form-check-input.input-primary:checked {
  border-color: #3ec9d6;
  background-color: #3ec9d6;
}
[data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:checked {
  border-color: #ecfafb;
  background-color: #ecfafb;
}
[data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%233ec9d6' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%233ec9d6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-10] .form-check .form-check-input.input-primary:focus[type=checkbox], [data-pc-preset=preset-10] .form-check .form-check-input.input-primary:focus[type=radio], [data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:focus[type=checkbox], [data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:focus[type=radio] {
  box-shadow: 0 0 0 0.2rem rgba(62, 201, 214, 0.25);
  border-color: #3ec9d6;
}
[data-pc-preset=preset-10] .form-check.form-switch .form-check-input.input-light-primary:checked {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%233ec9d6'/%3e%3c/svg%3e");
}
[data-pc-preset=preset-10] .btn-light-primary {
  background: #ecfafb;
  color: #3ec9d6;
  border-color: #ecfafb;
}
[data-pc-preset=preset-10] .btn-light-primary .material-icons-two-tone {
  background-color: #3ec9d6;
}
[data-pc-preset=preset-10] .btn-light-primary:hover {
  background: #3ec9d6;
  color: #fff;
  border-color: #3ec9d6;
}
[data-pc-preset=preset-10] .btn-light-primary.focus, [data-pc-preset=preset-10] .btn-light-primary:focus {
  background: #3ec9d6;
  color: #fff;
  border-color: #3ec9d6;
}
[data-pc-preset=preset-10] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-10] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-10] .btn-light-primary.dropdown-toggle {
  background: #3ec9d6;
  color: #fff;
  border-color: #3ec9d6;
}
[data-pc-preset=preset-10] .btn-check:active + .btn-light-primary,
[data-pc-preset=preset-10] .btn-check:checked + .btn-light-primary {
  background: #3ec9d6;
  color: #fff;
  border-color: #3ec9d6;
}
[data-pc-preset=preset-10] .btn-link-primary {
  background: transparent;
  color: #3ec9d6;
  border-color: transparent;
}
[data-pc-preset=preset-10] .btn-link-primary .material-icons-two-tone {
  background-color: #3ec9d6;
}
[data-pc-preset=preset-10] .btn-link-primary:hover {
  background: #ecfafb;
  color: #3ec9d6;
  border-color: #ecfafb;
}
[data-pc-preset=preset-10] .btn-link-primary.focus, [data-pc-preset=preset-10] .btn-link-primary:focus {
  background: #ecfafb;
  color: #3ec9d6;
  border-color: #ecfafb;
}
[data-pc-preset=preset-10] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-preset=preset-10] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-preset=preset-10] .btn-link-primary.dropdown-toggle {
  background: #ecfafb;
  color: #3ec9d6;
  border-color: #ecfafb;
}
[data-pc-preset=preset-10] .btn-check:active + .btn-link-primary,
[data-pc-preset=preset-10] .btn-check:checked + .btn-link-primary {
  background: #ecfafb;
  color: #3ec9d6;
  border-color: #ecfafb;
}

[data-pc-direction=rtl] {
  direction: rtl;
  text-align: right;
}
[data-pc-direction=rtl] .p-l-0 {
  padding-right: 0px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-l-0[class*=col] {
  padding-left: 15px;
}
[data-pc-direction=rtl] .p-r-0 {
  padding-left: 0px;
  padding-right: 0;
}
[data-pc-direction=rtl] .p-r-0[class*=col] {
  padding-right: 15px;
}
[data-pc-direction=rtl] .m-l-0 {
  margin-right: 0px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-0 {
  margin-left: 0px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-5 {
  padding-right: 5px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-5 {
  padding-left: 5px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-5 {
  margin-right: 5px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-5 {
  margin-left: 5px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-10 {
  padding-right: 10px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-10 {
  padding-left: 10px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-10 {
  margin-right: 10px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-10 {
  margin-left: 10px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-15 {
  padding-right: 15px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-15 {
  padding-left: 15px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-15 {
  margin-right: 15px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-15 {
  margin-left: 15px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-20 {
  padding-right: 20px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-20 {
  padding-left: 20px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-20 {
  margin-right: 20px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-20 {
  margin-left: 20px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-25 {
  padding-right: 25px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-25 {
  padding-left: 25px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-25 {
  margin-right: 25px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-25 {
  margin-left: 25px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-30 {
  padding-right: 30px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-30 {
  padding-left: 30px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-30 {
  margin-right: 30px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-30 {
  margin-left: 30px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-35 {
  padding-right: 35px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-35 {
  padding-left: 35px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-35 {
  margin-right: 35px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-35 {
  margin-left: 35px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-40 {
  padding-right: 40px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-40 {
  padding-left: 40px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-40 {
  margin-right: 40px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-40 {
  margin-left: 40px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-45 {
  padding-right: 45px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-45 {
  padding-left: 45px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-45 {
  margin-right: 45px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-45 {
  margin-left: 45px;
  margin-right: 0;
}
[data-pc-direction=rtl] .p-l-50 {
  padding-right: 50px;
  padding-left: 0;
}
[data-pc-direction=rtl] .p-r-50 {
  padding-left: 50px;
  padding-right: 0;
}
[data-pc-direction=rtl] .m-l-50 {
  margin-right: 50px;
  margin-left: 0;
}
[data-pc-direction=rtl] .m-r-50 {
  margin-left: 50px;
  margin-right: 0;
}
[data-pc-direction=rtl] ol:not([class]),
[data-pc-direction=rtl] ul:not([class]) {
  padding-left: 0;
  padding-right: 2rem;
}
[data-pc-direction=rtl] dl,
[data-pc-direction=rtl] .list-inline,
[data-pc-direction=rtl] .list-group,
[data-pc-direction=rtl] .list-unstyled {
  padding-right: 0;
  padding-left: 0;
}
[data-pc-direction=rtl] dd {
  margin-right: 0;
}
[data-pc-direction=rtl] .breadcrumb-item + .breadcrumb-item::before {
  float: right;
}
[data-pc-direction=rtl] .dropdown-menu {
  text-align: right;
}
[data-pc-direction=rtl] .dropdown .dropdown-item i {
  margin-right: 0;
  margin-left: 10px;
}
[data-pc-direction=rtl] .alert-dismissible {
  padding-right: 1.25rem;
  padding-left: 3.75rem;
}
[data-pc-direction=rtl] .alert-dismissible .btn-close {
  right: auto;
  left: 0;
}
[data-pc-direction=rtl] .accordion-button::after {
  margin-right: auto;
  margin-left: 0;
}
[data-pc-direction=rtl] .pc-container {
  margin-right: 280px;
  margin-left: 0px;
}
@media (max-width: 1024px) {
  [data-pc-direction=rtl] .pc-container {
    margin-right: 0px;
  }
}
[data-pc-direction=rtl] .pct-c-btn {
  right: auto;
  left: 0;
  border-left: none;
  border-right: 4px solid #ffffff;
  border-radius: 4px 50% 50% 4px;
}
[data-pc-direction=rtl] .pct-c-btn a {
  padding: 12px 16px 12px 12px;
}
[data-pc-direction=rtl] .pc-sidebar {
  border-left: var(--pc-sidebar-border);
  border-right: none;
}
[data-pc-direction=rtl] .pc-sidebar ul {
  padding-right: 0;
}
[data-pc-direction=rtl] .pc-sidebar .pc-badge,
[data-pc-direction=rtl] .pc-sidebar .pc-arrow {
  float: left;
}
[data-pc-direction=rtl] .pc-sidebar .pc-micon {
  margin-right: 0;
  margin-left: 15px;
}
@media (min-width: 1025px) {
  [data-pc-direction=rtl] .pc-sidebar.pc-sidebar-hide {
    transform: translateX(260px);
  }
  [data-pc-direction=rtl] .pc-sidebar.pc-sidebar-hide ~ .pc-footer,
  [data-pc-direction=rtl] .pc-sidebar.pc-sidebar-hide ~ .pc-container {
    margin-right: 0px;
  }
  [data-pc-direction=rtl] .pc-sidebar.pc-sidebar-hide ~ .pc-header {
    right: 0;
  }
}
@media (max-width: 1024px) {
  [data-pc-direction=rtl] .pc-sidebar {
    left: auto;
    right: -280px;
  }
  [data-pc-direction=rtl] .pc-sidebar.mob-sidebar-active {
    right: 0;
  }
  [data-pc-direction=rtl] .pc-sidebar ~ .pc-header {
    right: 0;
  }
}
[data-pc-direction=rtl] .pc-sidebar .pc-submenu .pc-link {
  padding: 10px 65px 10px 30px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-submenu .pc-link:after {
  left: auto;
  right: 45px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-submenu .pc-submenu .pc-link {
  padding: 10px 85px 10px 30px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-submenu .pc-submenu .pc-link:after {
  left: auto;
  right: 65px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-submenu .pc-submenu .pc-submenu .pc-link {
  padding: 10px 105px 10px 30px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-submenu .pc-submenu .pc-submenu .pc-link:after {
  left: auto;
  right: 85px;
}
[data-pc-direction=rtl] .pc-sidebar .pc-navbar > li > .pc-submenu::before {
  right: 40px;
  left: auto;
}
[data-pc-direction=rtl] .pc-header {
  left: 0;
  right: 280px;
}
[data-pc-direction=rtl] .pc-header ul {
  padding-right: 0;
}
[data-pc-direction=rtl] .pc-header .m-header {
  padding: 16px 24px 16px 10px;
}
[data-pc-direction=rtl] .pc-header .user-avtar {
  margin-right: 0;
  margin-left: 10px;
}
[data-pc-direction=rtl] .pc-header .header-search .icon-search {
  left: auto;
  right: 15px;
}
[data-pc-direction=rtl] .pc-header .header-search .btn-search {
  right: auto;
  left: 9px;
}
[data-pc-direction=rtl] .pc-header .pc-h-dropdown:not(.dropdown-menu-end) {
  right: 0 !important;
  left: auto !important;
}
[data-pc-direction=rtl] .pc-header .pc-h-dropdown.dropdown-menu-end {
  right: auto !important;
  left: 0 !important;
}
[data-pc-direction=rtl] .pc-header .dropdown-user-profile .upgradeplan-block::before {
  right: auto;
  left: -70px;
}
[data-pc-direction=rtl] .pc-header .dropdown-user-profile .upgradeplan-block::after {
  right: auto;
  left: -150px;
}
[data-pc-direction=rtl] .pc-header .dropdown-user-profile .settings-block .form-switch {
  padding-left: 2.5em;
}
[data-pc-direction=rtl] .pc-header .dropdown-user-profile .settings-block .form-switch .form-check-input {
  float: left;
}
[data-pc-direction=rtl] .page-header h5,
[data-pc-direction=rtl] .page-header .h5 {
  border-left: 1px solid #dae0e5;
  border-right: none;
  margin-right: 0;
  padding-right: 0;
  margin-left: 8px;
  padding-left: 8px;
}
[data-pc-direction=rtl] .pc-footer {
  margin-left: 0px;
  margin-right: 280px;
}
@media (max-width: 1024px) {
  [data-pc-direction=rtl] .pc-footer {
    margin-right: 0px;
  }
}
[data-pc-direction=rtl] .social-widget-card i {
  right: auto;
  left: 14px;
}
[data-pc-direction=rtl] .table tr td .row {
  margin-right: 0;
  margin-left: 50px;
}
[data-pc-direction=rtl] .task-card li {
  padding-left: 0;
  padding-right: 30px;
}
[data-pc-direction=rtl] .task-card li .task-icon {
  right: 3px;
  left: auto;
}
[data-pc-direction=rtl] .task-card .task-list:after {
  right: 10px;
  left: auto;
}
[data-pc-direction=rtl] .task-card .task-list:before {
  right: 3px;
  left: auto;
}
[data-pc-direction=rtl] .new-cust-card .align-middle .status {
  left: 0;
  right: auto;
}
[data-pc-direction=rtl] .apexcharts-legend-marker {
  margin-left: 3px;
}
[data-pc-direction=rtl] .btn-group .btn {
  border-radius: var(--bs-btn-border-radius) !important;
}
[data-pc-direction=rtl] .btn-group .btn:not(:last-child):not(.dropdown-toggle) {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
[data-pc-direction=rtl] .btn-group .btn:not(:first-child) {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
[data-pc-direction=rtl] .page-item:not(:first-child) .page-link {
  margin-left: 0;
  margin-right: -1px;
}
[data-pc-direction=rtl] .page-item:last-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
[data-pc-direction=rtl] .page-item:first-child .page-link {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}
[data-pc-direction=rtl] .dropdown-toggle::after {
  margin-left: 0;
  margin-right: 0.255em;
}
[data-pc-direction=rtl] .modal-header .btn-close {
  margin: calc(var(--bs-modal-header-padding-y) * -0.5) auto calc(var(--bs-modal-header-padding-x) * -0.5) calc(var(--bs-modal-header-padding-y) * -0.5);
}
[data-pc-direction=rtl] .toast-header .btn-close {
  margin-right: var(--bs-toast-padding-x);
  margin-left: calc(var(--bs-toast-padding-x) * -0.5);
}
[data-pc-direction=rtl] .notifier-container {
  right: auto;
  left: 4px;
}
[data-pc-direction=rtl] .notifier-container .notifier:not(.shown) {
  transform: translateX(-100%);
}
[data-pc-direction=rtl] .vtree,
[data-pc-direction=rtl] pre[class*=language-] > code {
  direction: ltr;
  text-align: left;
}
[data-pc-direction=rtl] select.form-control,
[data-pc-direction=rtl] select.custom-select,
[data-pc-direction=rtl] select.dataTable-selector,
[data-pc-direction=rtl] select.dataTable-input,
[data-pc-direction=rtl] .form-select {
  background-position: left 0.75rem center;
}
[data-pc-direction=rtl] .form-check {
  padding-left: 0;
  padding-right: 1.75em;
}
[data-pc-direction=rtl] .form-check .form-check-input {
  float: right;
  margin-right: -1.75em;
}
[data-pc-direction=rtl] select.custom-select,
[data-pc-direction=rtl] select.dataTable-selector,
[data-pc-direction=rtl] .form-select {
  padding-left: 2rem;
  padding-right: 0.75rem;
}
[data-pc-direction=rtl] .form-switch {
  padding-left: 0;
  padding-right: 2.5em;
}
[data-pc-direction=rtl] .form-switch .form-check-input {
  margin-right: -2.5em;
  background-position: right center;
}
[data-pc-direction=rtl] .form-switch .form-check-input:checked {
  background-position: left center;
}
[data-pc-direction=rtl] .custom-switch-v1.form-switch {
  padding-right: 2.9em;
}
[data-pc-direction=rtl] .custom-switch-v1.form-switch .form-check-input {
  margin-right: -2.9em;
}
[data-pc-direction=rtl] .input-group > * {
  border-radius: 8px !important;
}
[data-pc-direction=rtl] .input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
[data-pc-direction=rtl] .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
[data-pc-direction=rtl] .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
[data-pc-direction=rtl] .input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
[data-pc-direction=rtl] .input-group > :not(:first-child):not(.dropdown-menu):not(.form-floating):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback),
[data-pc-direction=rtl] .input-group > .form-floating:not(:first-child) > .form-control,
[data-pc-direction=rtl] .input-group > .form-floating:not(:first-child) > .form-select {
  margin-right: -1px;
  margin-left: 0;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
[data-pc-direction=rtl] .form-floating > label {
  width: auto;
  left: auto;
  right: 0;
}
[data-pc-direction=rtl] .form-check-inline {
  margin-right: 0;
  margin-left: 1rem;
}
[data-pc-direction=rtl] .choices[data-type*=select-multiple] .choices__button,
[data-pc-direction=rtl] .choices[data-type*=text] .choices__button {
  margin: 0 8px 0 -4px;
  border-left: none;
  border-right: 1px solid rgba(255, 255, 255, 0.35);
}
[data-pc-direction=rtl] .choices[data-type*=select-one]:after {
  right: auto;
  left: 11.5px;
}
[data-pc-direction=rtl] .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
  right: auto;
  left: 0;
}
[data-pc-direction=rtl] .editor-toolbar,
[data-pc-direction=rtl] .CodeMirror {
  direction: ltr;
  text-align: left;
}
[data-pc-direction=rtl] .dataTable-sorter::before,
[data-pc-direction=rtl] .dataTable-sorter::after {
  right: auto;
  left: 4px;
}
[data-pc-direction=rtl] .dataTable-dropdown label select {
  margin-right: 0;
  margin-left: 8px;
}
[data-pc-direction=rtl] .dataTables_length select.form-select {
  padding-right: 0.7rem;
  padding-left: 30px;
}
[data-pc-direction=rtl] div.dataTables_wrapper div.dataTables_filter input {
  margin-left: 0;
  margin-right: 0.5em;
}
[data-pc-direction=rtl] div.dataTables_wrapper div.dataTables_filter {
  text-align: left;
}
[data-pc-direction=rtl] table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control,
[data-pc-direction=rtl] table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control {
  padding-left: 0.75rem;
  padding-right: 30px;
}
[data-pc-direction=rtl] table.dataTable.dtr-inline.collapsed > tbody > tr > td.dtr-control:before,
[data-pc-direction=rtl] table.dataTable.dtr-inline.collapsed > tbody > tr > th.dtr-control:before {
  left: auto;
  right: 5px;
}
[data-pc-direction=rtl] .pc-icon-checkbox {
  padding-right: 0;
}
[data-pc-direction=rtl] #cke5-inline-demo .demo-row .demo-row__half:first-of-type {
  padding-right: 0;
  padding-left: 0.5rem;
}
[data-pc-direction=rtl] #cke5-inline-demo .demo-row .demo-row__half:last-of-type {
  padding-right: 0.5rem;
  padding-left: 0;
}
[data-pc-direction=rtl] .mail-wrapper .mail-menulist {
  margin-right: 0;
  margin-left: var(--bs-gutter-x);
}
[data-pc-direction=rtl] .mail-option .mail-buttons {
  right: auto;
  left: 5px;
  transform-origin: left;
}
[data-pc-direction=rtl] .mail-wrapper .mail-menulist .list-group-item-action .material-icons-two-tone {
  margin-left: 8px;
  margin-right: 0;
}
[data-pc-direction=rtl] .nav {
  padding-right: 0;
}
[data-pc-direction=rtl] .chat-wrapper .chat-user_list {
  margin-right: 0;
  margin-left: var(--bs-gutter-x);
}
[data-pc-direction=rtl] .chat-wrapper .chat-user_info {
  margin-right: var(--bs-gutter-x);
  margin-left: 0;
}
[data-pc-direction=rtl] .table-card .card-body .table tr td:first-child,
[data-pc-direction=rtl] .table-card .card-body .table tr th:first-child,
[data-pc-direction=rtl] .table-body.card-body .table tr td:first-child,
[data-pc-direction=rtl] .table-body.card-body .table tr th:first-child {
  padding-right: 25px;
  padding-left: 0.75rem;
}
[data-pc-direction=rtl] .table-card .card-body .table tr td:last-child,
[data-pc-direction=rtl] .table-card .card-body .table tr th:last-child,
[data-pc-direction=rtl] .table-body.card-body .table tr td:last-child,
[data-pc-direction=rtl] .table-body.card-body .table tr th:last-child {
  padding-right: 25px;
  padding-left: 0.75rem;
}
[data-pc-direction=rtl] .dataTable-table th a {
  margin-left: 16px;
  padding-right: 0;
}
[data-pc-direction=rtl] .list-inline-item:not(:last-child) {
  margin-right: 0;
  margin-left: 0.5rem;
}
[data-pc-direction=rtl] .was-validated .form-select:valid:not([multiple]):not([size]), [data-pc-direction=rtl] .was-validated .form-select:valid:not([multiple])[size="1"], [data-pc-direction=rtl] .form-select.is-valid:not([multiple]):not([size]), [data-pc-direction=rtl] .form-select.is-valid:not([multiple])[size="1"], [data-pc-direction=rtl] .was-validated .form-select:invalid:not([multiple]):not([size]), [data-pc-direction=rtl] .was-validated .form-select:invalid:not([multiple])[size="1"], [data-pc-direction=rtl] .form-select.is-invalid:not([multiple]):not([size]), [data-pc-direction=rtl] .form-select.is-invalid:not([multiple])[size="1"] {
  background-position: left 0.75rem center, center left 2.75rem;
}
[data-pc-direction=rtl] .was-validated textarea.form-control:invalid, [data-pc-direction=rtl] .was-validated textarea.custom-select:invalid, [data-pc-direction=rtl] .was-validated textarea.dataTable-selector:invalid, [data-pc-direction=rtl] .was-validated textarea.dataTable-input:invalid, [data-pc-direction=rtl] textarea.form-control.is-invalid, [data-pc-direction=rtl] textarea.is-invalid.custom-select, [data-pc-direction=rtl] textarea.is-invalid.dataTable-selector, [data-pc-direction=rtl] textarea.is-invalid.dataTable-input,
[data-pc-direction=rtl] .was-validated textarea.form-control:valid, [data-pc-direction=rtl] .was-validated textarea.custom-select:valid, [data-pc-direction=rtl] .was-validated textarea.dataTable-selector:valid, [data-pc-direction=rtl] .was-validated textarea.dataTable-input:valid, [data-pc-direction=rtl] textarea.form-control.is-valid, [data-pc-direction=rtl] textarea.is-valid.custom-select, [data-pc-direction=rtl] textarea.is-valid.dataTable-selector, [data-pc-direction=rtl] textarea.is-valid.dataTable-input {
  background-position: top calc(0.375em + 0.4rem) left calc(0.375em + 0.4rem);
  padding-left: calc(1.5em + 1.6rem);
  padding-right: 0.75rem;
}
[data-pc-direction=rtl] .was-validated .form-control:invalid, [data-pc-direction=rtl] .was-validated .custom-select:invalid, [data-pc-direction=rtl] .was-validated .dataTable-selector:invalid, [data-pc-direction=rtl] .was-validated .dataTable-input:invalid, [data-pc-direction=rtl] .form-control.is-invalid, [data-pc-direction=rtl] .is-invalid.custom-select, [data-pc-direction=rtl] .is-invalid.dataTable-selector, [data-pc-direction=rtl] .is-invalid.dataTable-input,
[data-pc-direction=rtl] .was-validated .form-control:valid, [data-pc-direction=rtl] .was-validated .custom-select:valid, [data-pc-direction=rtl] .was-validated .dataTable-selector:valid, [data-pc-direction=rtl] .was-validated .dataTable-input:valid, [data-pc-direction=rtl] .form-control.is-valid, [data-pc-direction=rtl] .is-valid.custom-select, [data-pc-direction=rtl] .is-valid.dataTable-selector, [data-pc-direction=rtl] .is-valid.dataTable-input {
  background-position: left calc(0.375em + 0.4rem) center;
  padding-left: calc(1.5em + 1.6rem);
  padding-right: 0.75rem;
}
[data-pc-direction=rtl] .pc-footer .footer-link .list-inline-item:not(:last-child) {
  margin-right: 0;
  margin-left: 0.9rem;
}
[data-pc-direction=rtl] .cropper {
  direction: ltr;
}
[data-pc-direction=rtl] .user-group .avtar + .avtar,
[data-pc-direction=rtl] .user-group .avtar + img,
[data-pc-direction=rtl] .user-group img + .avtar,
[data-pc-direction=rtl] .user-group img + img {
  margin-left: 0;
  margin-right: -14px;
}
[data-pc-direction=rtl] .auth-main .auth-wrapper .auth-form img + span {
  padding-left: 0;
  padding-right: 10px;
}
[data-pc-direction=rtl] .progress-stacked {
  overflow: hidden;
}
[data-pc-direction=rtl] .progress-stacked .progress:not(:first-child) .progress-bar {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
[data-pc-direction=rtl] .progress-stacked .progress:not(:last-child) .progress-bar {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
[data-pc-direction=rtl] .tns-outer {
  direction: ltr;
}
[data-pc-direction=rtl] .apexcharts-tooltip-marker {
  margin-right: 0;
  margin-left: 10px;
}
[data-pc-direction=rtl] .apexcharts-legend-text {
  margin-left: 0;
}

[data-pc-theme=dark] {
  --bs-body-bg: #131920;
  --bs-body-bg-rgb: 19, 25, 32;
  --pc-heading-color: rgba(255, 255, 255, 0.8);
  --pc-sidebar-background: transparent;
  --pc-sidebar-color: rgba(255, 255, 255, 0.5);
  --pc-sidebar-shadow: none;
  --pc-sidebar-caption-color: rgba(255, 255, 255, 0.8);
  --pc-sidebar-border: 1px dashed #242d39;
  --pc-sidebar-user-background: #1b232d;
  --pc-header-background: rgba(var(--bs-body-bg-rgb), 0.7);
  --pc-header-color: #6f747f;
  --pc-header-shadow: none;
  --pc-active-background: #19212a;
  --pc-card-box-shadow: none;
  --bs-heading-color: #fafafa;
  --bs-body-color: #bfbfbf;
  --bs-body-color-rgb: to-rgb(#bfbfbf);
  --bs-border-color: #1d2630;
}
[data-pc-theme=dark] .card {
  --bs-card-border-color: #1d2630;
  --bs-card-bg: #1b232d;
}
[data-pc-theme=dark] .offcanvas,
[data-pc-theme=dark] .offcanvas-xxl,
[data-pc-theme=dark] .offcanvas-xl,
[data-pc-theme=dark] .offcanvas-lg,
[data-pc-theme=dark] .offcanvas-md,
[data-pc-theme=dark] .offcanvas-sm {
  --bs-offcanvas-bg: #19212a;
}
[data-pc-theme=dark] .list-group {
  --bs-list-group-bg: #1b232d;
  --bs-list-group-border-color: #202b36;
  --bs-list-group-disabled-bg: #171e26;
  --bs-list-group-action-hover-bg: #1d2630;
  --bs-list-group-action-hover-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: #1d2630;
  --bs-list-group-action-active-color: var(--bs-body-color);
}
[data-pc-theme=dark] .preset-btn {
  --bs-gray-300: #1d2630;
}
[data-pc-theme=dark] .progress {
  --bs-progress-bg: #1d2630;
}
[data-pc-theme=dark] .btn:not(.btn-light) {
  --bs-btn-color: var(--bs-heading-color);
}
[data-pc-theme=dark] .pct-c-btn {
  background: #1d2630;
  border-color: #1d2630;
}
[data-pc-theme=dark] .dropdown-menu {
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-bg: #263240;
  --bs-dropdown-link-hover-bg: #19212a;
  --bs-dropdown-divider-bg: #1d2630;
}
[data-pc-theme=dark] .pagination {
  --bs-pagination-bg: #19212a;
  --bs-pagination-border-color: #202b36;
  --bs-pagination-hover-bg: #1e2833;
  --bs-pagination-hover-border-color: #202b36;
  --bs-pagination-focus-bg: #1e2833;
  --bs-pagination-disabled-bg: #151c23;
  --bs-pagination-disabled-border-color: #202b36;
}
[data-pc-theme=dark] .accordion-item {
  --bs-accordion-bg: #19212a;
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-btn-bg: #19212a;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-active-bg: rgba(var(--bs-primary-rgb), 0.2);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23dbe0e5'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .navbar.navbar-light .navbar-nav {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #ffffff;
  --bs-navbar-brand-color: #ffffff;
  --bs-navbar-brand-hover-color: #ffffff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .btn-close {
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 16 16'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}
[data-pc-theme=dark] .btn-close.btn-close-white {
  filter: none;
}
[data-pc-theme=dark] .modal {
  --bs-modal-bg: #1b232d;
  --bs-modal-header-border-color: #202b36;
  --bs-modal-footer-border-color: #202b36;
}
[data-pc-theme=dark] .toast {
  --bs-toast-bg: #1b232d;
  --bs-toast-color: var(--bs-body-color);
  --bs-toast-header-bg: #1e2833;
  --bs-toast-header-color: var(--bs-heading-color);
}
[data-pc-theme=dark] .vtree a.vtree-leaf-label {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .vtree li.vtree-leaf a.vtree-leaf-label:hover,
[data-pc-theme=dark] .vtree li.vtree-leaf.vtree-selected > a.vtree-leaf-label {
  background-color: #222d3a;
  outline-color: #222d3a;
}
[data-pc-theme=dark] .custom-select,
[data-pc-theme=dark] .dataTable-selector,
[data-pc-theme=dark] .dataTable-input,
[data-pc-theme=dark] .form-select,
[data-pc-theme=dark] .form-control {
  background-color: #263240;
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .custom-select:not(:focus),
[data-pc-theme=dark] .dataTable-selector:not(:focus),
[data-pc-theme=dark] .dataTable-input:not(:focus),
[data-pc-theme=dark] .form-select:not(:focus),
[data-pc-theme=dark] .form-control:not(:focus) {
  border-color: #303f50;
}
[data-pc-theme=dark] .form-control-plaintext {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .form-check-input:not(:checked),
[data-pc-theme=dark] .input-group-text {
  background-color: #263240;
  color: var(--bs-body-color);
  border-color: #303f50;
}
[data-pc-theme=dark] .form-control::file-selector-button {
  background-color: #263240;
  color: var(--bs-body-color);
  border-color: #303f50;
}
[data-pc-theme=dark] .form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #202b36;
}
[data-pc-theme=dark] select.form-control,
[data-pc-theme=dark] select.custom-select,
[data-pc-theme=dark] select.dataTable-selector,
[data-pc-theme=dark] select.dataTable-input,
[data-pc-theme=dark] .form-select:not([multiple]) {
  background-color: #263240;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%236f747f' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 16px 12px;
}
[data-pc-theme=dark] .form-range::-webkit-slider-runnable-track {
  background-color: #263240;
}
[data-pc-theme=dark] .drp-search .form-control {
  background: transparent;
}
[data-pc-theme=dark] .alert-theme {
  color: #ff3366;
  background: rgba(255, 51, 102, 0.2);
  border-color: rgba(255, 51, 102, 0.2);
}
[data-pc-theme=dark] .alert-theme .alert-link {
  color: #ff3366;
}
[data-pc-theme=dark] .badge.bg-light-theme {
  background: rgba(255, 51, 102, 0.2);
  color: #ff3366;
  border-color: rgba(255, 51, 102, 0.2);
}
[data-pc-theme=dark] .icon-svg-theme {
  fill: rgba(255, 51, 102, 0.2);
  stroke: #ff3366;
}
[data-pc-theme=dark] .bg-light-theme {
  background: rgba(255, 51, 102, 0.2);
}
[data-pc-theme=dark] .btn-light-theme:not(:hover) {
  background: rgba(255, 51, 102, 0.2) !important;
  border-color: rgba(255, 51, 102, 0.2);
}
[data-pc-theme=dark] .btn-link-theme:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-theme:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-theme:active, [data-pc-theme=dark] .btn-link-theme:focus, [data-pc-theme=dark] .btn-link-theme:hover {
  background: rgba(255, 51, 102, 0.2);
  border-color: rgba(255, 51, 102, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-theme:checked {
  border-color: rgba(255, 51, 102, 0.2);
  background-color: rgba(255, 51, 102, 0.2);
}
[data-pc-theme=dark] .alert-primary {
  color: #0d6efd;
  background: rgba(13, 110, 253, 0.2);
  border-color: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .alert-primary .alert-link {
  color: #0d6efd;
}
[data-pc-theme=dark] .badge.bg-light-primary {
  background: rgba(13, 110, 253, 0.2);
  color: #0d6efd;
  border-color: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .icon-svg-primary {
  fill: rgba(13, 110, 253, 0.2);
  stroke: #0d6efd;
}
[data-pc-theme=dark] .bg-light-primary {
  background: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .btn-light-primary:not(:hover) {
  background: rgba(13, 110, 253, 0.2) !important;
  border-color: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-primary:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-primary:active, [data-pc-theme=dark] .btn-link-primary:focus, [data-pc-theme=dark] .btn-link-primary:hover {
  background: rgba(13, 110, 253, 0.2);
  border-color: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(13, 110, 253, 0.2);
  background-color: rgba(13, 110, 253, 0.2);
}
[data-pc-theme=dark] .alert-secondary {
  color: #5b6b79;
  background: rgba(91, 107, 121, 0.2);
  border-color: rgba(91, 107, 121, 0.2);
}
[data-pc-theme=dark] .alert-secondary .alert-link {
  color: #5b6b79;
}
[data-pc-theme=dark] .badge.bg-light-secondary {
  background: rgba(91, 107, 121, 0.2);
  color: #5b6b79;
  border-color: rgba(91, 107, 121, 0.2);
}
[data-pc-theme=dark] .icon-svg-secondary {
  fill: rgba(91, 107, 121, 0.2);
  stroke: #5b6b79;
}
[data-pc-theme=dark] .bg-light-secondary {
  background: rgba(91, 107, 121, 0.2);
}
[data-pc-theme=dark] .btn-light-secondary:not(:hover) {
  background: rgba(91, 107, 121, 0.2) !important;
  border-color: rgba(91, 107, 121, 0.2);
}
[data-pc-theme=dark] .btn-link-secondary:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-secondary:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-secondary:active, [data-pc-theme=dark] .btn-link-secondary:focus, [data-pc-theme=dark] .btn-link-secondary:hover {
  background: rgba(91, 107, 121, 0.2);
  border-color: rgba(91, 107, 121, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-secondary:checked {
  border-color: rgba(91, 107, 121, 0.2);
  background-color: rgba(91, 107, 121, 0.2);
}
[data-pc-theme=dark] .alert-success {
  color: #198754;
  background: rgba(25, 135, 84, 0.2);
  border-color: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .alert-success .alert-link {
  color: #198754;
}
[data-pc-theme=dark] .badge.bg-light-success {
  background: rgba(25, 135, 84, 0.2);
  color: #198754;
  border-color: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .icon-svg-success {
  fill: rgba(25, 135, 84, 0.2);
  stroke: #198754;
}
[data-pc-theme=dark] .bg-light-success {
  background: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .btn-light-success:not(:hover) {
  background: rgba(25, 135, 84, 0.2) !important;
  border-color: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .btn-link-success:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-success:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-success:active, [data-pc-theme=dark] .btn-link-success:focus, [data-pc-theme=dark] .btn-link-success:hover {
  background: rgba(25, 135, 84, 0.2);
  border-color: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-success:checked {
  border-color: rgba(25, 135, 84, 0.2);
  background-color: rgba(25, 135, 84, 0.2);
}
[data-pc-theme=dark] .alert-info {
  color: #0dcaf0;
  background: rgba(13, 202, 240, 0.2);
  border-color: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .alert-info .alert-link {
  color: #0dcaf0;
}
[data-pc-theme=dark] .badge.bg-light-info {
  background: rgba(13, 202, 240, 0.2);
  color: #0dcaf0;
  border-color: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .icon-svg-info {
  fill: rgba(13, 202, 240, 0.2);
  stroke: #0dcaf0;
}
[data-pc-theme=dark] .bg-light-info {
  background: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .btn-light-info:not(:hover) {
  background: rgba(13, 202, 240, 0.2) !important;
  border-color: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .btn-link-info:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-info:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-info:active, [data-pc-theme=dark] .btn-link-info:focus, [data-pc-theme=dark] .btn-link-info:hover {
  background: rgba(13, 202, 240, 0.2);
  border-color: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-info:checked {
  border-color: rgba(13, 202, 240, 0.2);
  background-color: rgba(13, 202, 240, 0.2);
}
[data-pc-theme=dark] .alert-warning {
  color: #ffc107;
  background: rgba(255, 193, 7, 0.2);
  border-color: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .alert-warning .alert-link {
  color: #ffc107;
}
[data-pc-theme=dark] .badge.bg-light-warning {
  background: rgba(255, 193, 7, 0.2);
  color: #ffc107;
  border-color: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .icon-svg-warning {
  fill: rgba(255, 193, 7, 0.2);
  stroke: #ffc107;
}
[data-pc-theme=dark] .bg-light-warning {
  background: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .btn-light-warning:not(:hover) {
  background: rgba(255, 193, 7, 0.2) !important;
  border-color: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .btn-link-warning:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-warning:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-warning:active, [data-pc-theme=dark] .btn-link-warning:focus, [data-pc-theme=dark] .btn-link-warning:hover {
  background: rgba(255, 193, 7, 0.2);
  border-color: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-warning:checked {
  border-color: rgba(255, 193, 7, 0.2);
  background-color: rgba(255, 193, 7, 0.2);
}
[data-pc-theme=dark] .alert-danger {
  color: #dc3545;
  background: rgba(220, 53, 69, 0.2);
  border-color: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .alert-danger .alert-link {
  color: #dc3545;
}
[data-pc-theme=dark] .badge.bg-light-danger {
  background: rgba(220, 53, 69, 0.2);
  color: #dc3545;
  border-color: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .icon-svg-danger {
  fill: rgba(220, 53, 69, 0.2);
  stroke: #dc3545;
}
[data-pc-theme=dark] .bg-light-danger {
  background: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .btn-light-danger:not(:hover) {
  background: rgba(220, 53, 69, 0.2) !important;
  border-color: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .btn-link-danger:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-danger:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-danger:active, [data-pc-theme=dark] .btn-link-danger:focus, [data-pc-theme=dark] .btn-link-danger:hover {
  background: rgba(220, 53, 69, 0.2);
  border-color: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-danger:checked {
  border-color: rgba(220, 53, 69, 0.2);
  background-color: rgba(220, 53, 69, 0.2);
}
[data-pc-theme=dark] .alert-light {
  color: #f8f9fa;
  background: rgba(248, 249, 250, 0.2);
  border-color: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .alert-light .alert-link {
  color: #f8f9fa;
}
[data-pc-theme=dark] .badge.bg-light-light {
  background: rgba(248, 249, 250, 0.2);
  color: #f8f9fa;
  border-color: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .icon-svg-light {
  fill: rgba(248, 249, 250, 0.2);
  stroke: #f8f9fa;
}
[data-pc-theme=dark] .bg-light-light {
  background: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .btn-light-light:not(:hover) {
  background: rgba(248, 249, 250, 0.2) !important;
  border-color: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .btn-link-light:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-light:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-light:active, [data-pc-theme=dark] .btn-link-light:focus, [data-pc-theme=dark] .btn-link-light:hover {
  background: rgba(248, 249, 250, 0.2);
  border-color: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-light:checked {
  border-color: rgba(248, 249, 250, 0.2);
  background-color: rgba(248, 249, 250, 0.2);
}
[data-pc-theme=dark] .alert-dark {
  color: #131920;
  background: rgba(19, 25, 32, 0.2);
  border-color: rgba(19, 25, 32, 0.2);
}
[data-pc-theme=dark] .alert-dark .alert-link {
  color: #131920;
}
[data-pc-theme=dark] .badge.bg-light-dark {
  background: rgba(19, 25, 32, 0.2);
  color: #131920;
  border-color: rgba(19, 25, 32, 0.2);
}
[data-pc-theme=dark] .icon-svg-dark {
  fill: rgba(19, 25, 32, 0.2);
  stroke: #131920;
}
[data-pc-theme=dark] .bg-light-dark {
  background: rgba(19, 25, 32, 0.2);
}
[data-pc-theme=dark] .btn-light-dark:not(:hover) {
  background: rgba(19, 25, 32, 0.2) !important;
  border-color: rgba(19, 25, 32, 0.2);
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .btn-link-dark:not(:disabled):not(.disabled).active, [data-pc-theme=dark] .btn-link-dark:not(:disabled):not(.disabled):active, [data-pc-theme=dark] .btn-link-dark:active, [data-pc-theme=dark] .btn-link-dark:focus, [data-pc-theme=dark] .btn-link-dark:hover {
  background: rgba(19, 25, 32, 0.2);
  border-color: rgba(19, 25, 32, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-dark:checked {
  border-color: rgba(19, 25, 32, 0.2);
  background-color: rgba(19, 25, 32, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-dark:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .form-check .form-check-input.input-light-dark:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e");
}
[data-pc-theme=dark] .form-check.form-switch .form-check-input.input-dark {
  background-color: rgba(255, 255, 255, 0.1);
}
[data-pc-theme=dark] .form-check.form-switch .form-check-input.input-light-dark {
  background-color: rgba(255, 255, 255, 0.1);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='4.1' fill='%23ffffff'/%3e%3c/svg%3e") !important;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(70, 128, 255, 0.2);
  background-color: rgba(70, 128, 255, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary {
  background: rgba(70, 128, 255, 0.2);
  color: #4680ff;
  border-color: rgba(70, 128, 255, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary .material-icons-two-tone {
  background-color: #4680ff;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary:hover {
  background: #4680ff;
  color: #fff;
  border-color: #4680ff;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary:focus {
  background: #4680ff;
  color: #fff;
  border-color: #4680ff;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-1] .btn-light-primary.dropdown-toggle {
  background: #4680ff;
  color: #fff;
  border-color: #4680ff;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-check:checked + .btn-light-primary {
  background: #4680ff;
  color: #fff;
  border-color: #4680ff;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary {
  background: transparent;
  color: #4680ff;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary .material-icons-two-tone {
  background-color: #4680ff;
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary:hover {
  background: rgba(70, 128, 255, 0.2);
  color: #4680ff;
  border-color: rgba(70, 128, 255, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary:focus {
  background: rgba(70, 128, 255, 0.2);
  color: #4680ff;
  border-color: rgba(70, 128, 255, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-1] .btn-link-primary.dropdown-toggle {
  background: rgba(70, 128, 255, 0.2);
  color: #4680ff;
  border-color: rgba(70, 128, 255, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-1] .btn-check:checked + .btn-link-primary {
  background: rgba(70, 128, 255, 0.2);
  color: #4680ff;
  border-color: rgba(70, 128, 255, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(102, 16, 242, 0.2);
  background-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary {
  background: rgba(102, 16, 242, 0.2);
  color: #6610f2;
  border-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary .material-icons-two-tone {
  background-color: #6610f2;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary:hover {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary:focus {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-2] .btn-light-primary.dropdown-toggle {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-check:checked + .btn-light-primary {
  background: #6610f2;
  color: #fff;
  border-color: #6610f2;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary {
  background: transparent;
  color: #6610f2;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary .material-icons-two-tone {
  background-color: #6610f2;
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary:hover {
  background: rgba(102, 16, 242, 0.2);
  color: #6610f2;
  border-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary:focus {
  background: rgba(102, 16, 242, 0.2);
  color: #6610f2;
  border-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-2] .btn-link-primary.dropdown-toggle {
  background: rgba(102, 16, 242, 0.2);
  color: #6610f2;
  border-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-2] .btn-check:checked + .btn-link-primary {
  background: rgba(102, 16, 242, 0.2);
  color: #6610f2;
  border-color: rgba(102, 16, 242, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(103, 58, 183, 0.2);
  background-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary {
  background: rgba(103, 58, 183, 0.2);
  color: #673ab7;
  border-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary:hover {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary:focus {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-3] .btn-light-primary.dropdown-toggle {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-check:checked + .btn-light-primary {
  background: #673ab7;
  color: #fff;
  border-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary {
  background: transparent;
  color: #673ab7;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary .material-icons-two-tone {
  background-color: #673ab7;
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary:hover {
  background: rgba(103, 58, 183, 0.2);
  color: #673ab7;
  border-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary:focus {
  background: rgba(103, 58, 183, 0.2);
  color: #673ab7;
  border-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-3] .btn-link-primary.dropdown-toggle {
  background: rgba(103, 58, 183, 0.2);
  color: #673ab7;
  border-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-3] .btn-check:checked + .btn-link-primary {
  background: rgba(103, 58, 183, 0.2);
  color: #673ab7;
  border-color: rgba(103, 58, 183, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(232, 62, 140, 0.2);
  background-color: rgba(232, 62, 140, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary {
  background: rgba(232, 62, 140, 0.2);
  color: #e83e8c;
  border-color: rgba(232, 62, 140, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary .material-icons-two-tone {
  background-color: #e83e8c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary:hover {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary:focus {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-4] .btn-light-primary.dropdown-toggle {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-check:checked + .btn-light-primary {
  background: #e83e8c;
  color: #fff;
  border-color: #e83e8c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary {
  background: transparent;
  color: #e83e8c;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary .material-icons-two-tone {
  background-color: #e83e8c;
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary:hover {
  background: rgba(232, 62, 140, 0.2);
  color: #e83e8c;
  border-color: rgba(232, 62, 140, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary:focus {
  background: rgba(232, 62, 140, 0.2);
  color: #e83e8c;
  border-color: rgba(232, 62, 140, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-4] .btn-link-primary.dropdown-toggle {
  background: rgba(232, 62, 140, 0.2);
  color: #e83e8c;
  border-color: rgba(232, 62, 140, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-4] .btn-check:checked + .btn-link-primary {
  background: rgba(232, 62, 140, 0.2);
  color: #e83e8c;
  border-color: rgba(232, 62, 140, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(220, 38, 38, 0.2);
  background-color: rgba(220, 38, 38, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary {
  background: rgba(220, 38, 38, 0.2);
  color: #dc2626;
  border-color: rgba(220, 38, 38, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary .material-icons-two-tone {
  background-color: #dc2626;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary:hover {
  background: #dc2626;
  color: #fff;
  border-color: #dc2626;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary:focus {
  background: #dc2626;
  color: #fff;
  border-color: #dc2626;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-5] .btn-light-primary.dropdown-toggle {
  background: #dc2626;
  color: #fff;
  border-color: #dc2626;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-check:checked + .btn-light-primary {
  background: #dc2626;
  color: #fff;
  border-color: #dc2626;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary {
  background: transparent;
  color: #dc2626;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary .material-icons-two-tone {
  background-color: #dc2626;
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary:hover {
  background: rgba(220, 38, 38, 0.2);
  color: #dc2626;
  border-color: rgba(220, 38, 38, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary:focus {
  background: rgba(220, 38, 38, 0.2);
  color: #dc2626;
  border-color: rgba(220, 38, 38, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-5] .btn-link-primary.dropdown-toggle {
  background: rgba(220, 38, 38, 0.2);
  color: #dc2626;
  border-color: rgba(220, 38, 38, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-5] .btn-check:checked + .btn-link-primary {
  background: rgba(220, 38, 38, 0.2);
  color: #dc2626;
  border-color: rgba(220, 38, 38, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(253, 126, 20, 0.2);
  background-color: rgba(253, 126, 20, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary {
  background: rgba(253, 126, 20, 0.2);
  color: #fd7e14;
  border-color: rgba(253, 126, 20, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary .material-icons-two-tone {
  background-color: #fd7e14;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary:hover {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary:focus {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-6] .btn-light-primary.dropdown-toggle {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-check:checked + .btn-light-primary {
  background: #fd7e14;
  color: #fff;
  border-color: #fd7e14;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary {
  background: transparent;
  color: #fd7e14;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary .material-icons-two-tone {
  background-color: #fd7e14;
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary:hover {
  background: rgba(253, 126, 20, 0.2);
  color: #fd7e14;
  border-color: rgba(253, 126, 20, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary:focus {
  background: rgba(253, 126, 20, 0.2);
  color: #fd7e14;
  border-color: rgba(253, 126, 20, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-6] .btn-link-primary.dropdown-toggle {
  background: rgba(253, 126, 20, 0.2);
  color: #fd7e14;
  border-color: rgba(253, 126, 20, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-6] .btn-check:checked + .btn-link-primary {
  background: rgba(253, 126, 20, 0.2);
  color: #fd7e14;
  border-color: rgba(253, 126, 20, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(229, 138, 0, 0.2);
  background-color: rgba(229, 138, 0, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary {
  background: rgba(229, 138, 0, 0.2);
  color: #e58a00;
  border-color: rgba(229, 138, 0, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary .material-icons-two-tone {
  background-color: #e58a00;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary:hover {
  background: #e58a00;
  color: #fff;
  border-color: #e58a00;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary:focus {
  background: #e58a00;
  color: #fff;
  border-color: #e58a00;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-7] .btn-light-primary.dropdown-toggle {
  background: #e58a00;
  color: #fff;
  border-color: #e58a00;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-check:checked + .btn-light-primary {
  background: #e58a00;
  color: #fff;
  border-color: #e58a00;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary {
  background: transparent;
  color: #e58a00;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary .material-icons-two-tone {
  background-color: #e58a00;
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary:hover {
  background: rgba(229, 138, 0, 0.2);
  color: #e58a00;
  border-color: rgba(229, 138, 0, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary:focus {
  background: rgba(229, 138, 0, 0.2);
  color: #e58a00;
  border-color: rgba(229, 138, 0, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-7] .btn-link-primary.dropdown-toggle {
  background: rgba(229, 138, 0, 0.2);
  color: #e58a00;
  border-color: rgba(229, 138, 0, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-7] .btn-check:checked + .btn-link-primary {
  background: rgba(229, 138, 0, 0.2);
  color: #e58a00;
  border-color: rgba(229, 138, 0, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-8] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(44, 168, 127, 0.2);
  background-color: rgba(44, 168, 127, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary {
  background: rgba(44, 168, 127, 0.2);
  color: #2ca87f;
  border-color: rgba(44, 168, 127, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary .material-icons-two-tone {
  background-color: #2ca87f;
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary:hover {
  background: #2ca87f;
  color: #fff;
  border-color: #2ca87f;
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary:focus {
  background: #2ca87f;
  color: #fff;
  border-color: #2ca87f;
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-8] .btn-light-primary.dropdown-toggle {
  background: #2ca87f;
  color: #fff;
  border-color: #2ca87f;
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-check:checked + .btn-light-primary {
  background: #2ca87f;
  color: #fff;
  border-color: #2ca87f;
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary {
  background: transparent;
  color: #2ca87f;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary .material-icons-two-tone {
  background-color: #2ca87f;
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary:hover {
  background: rgba(44, 168, 127, 0.2);
  color: #2ca87f;
  border-color: rgba(44, 168, 127, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary:focus {
  background: rgba(44, 168, 127, 0.2);
  color: #2ca87f;
  border-color: rgba(44, 168, 127, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-8] .btn-link-primary.dropdown-toggle {
  background: rgba(44, 168, 127, 0.2);
  color: #2ca87f;
  border-color: rgba(44, 168, 127, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-8] .btn-check:checked + .btn-link-primary {
  background: rgba(44, 168, 127, 0.2);
  color: #2ca87f;
  border-color: rgba(44, 168, 127, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-9] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(0, 128, 128, 0.2);
  background-color: rgba(0, 128, 128, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary {
  background: rgba(0, 128, 128, 0.2);
  color: #008080;
  border-color: rgba(0, 128, 128, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary .material-icons-two-tone {
  background-color: #008080;
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary:hover {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary:focus {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-9] .btn-light-primary.dropdown-toggle {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-check:checked + .btn-light-primary {
  background: #008080;
  color: #fff;
  border-color: #008080;
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary {
  background: transparent;
  color: #008080;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary .material-icons-two-tone {
  background-color: #008080;
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary:hover {
  background: rgba(0, 128, 128, 0.2);
  color: #008080;
  border-color: rgba(0, 128, 128, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary:focus {
  background: rgba(0, 128, 128, 0.2);
  color: #008080;
  border-color: rgba(0, 128, 128, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-9] .btn-link-primary.dropdown-toggle {
  background: rgba(0, 128, 128, 0.2);
  color: #008080;
  border-color: rgba(0, 128, 128, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-9] .btn-check:checked + .btn-link-primary {
  background: rgba(0, 128, 128, 0.2);
  color: #008080;
  border-color: rgba(0, 128, 128, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-10] .form-check .form-check-input.input-light-primary:checked {
  border-color: rgba(62, 201, 214, 0.2);
  background-color: rgba(62, 201, 214, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary {
  background: rgba(62, 201, 214, 0.2);
  color: #3ec9d6;
  border-color: rgba(62, 201, 214, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary .material-icons-two-tone {
  background-color: #3ec9d6;
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary:hover {
  background: #3ec9d6;
  color: #fff;
  border-color: #3ec9d6;
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary:focus {
  background: #3ec9d6;
  color: #fff;
  border-color: #3ec9d6;
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-10] .btn-light-primary.dropdown-toggle {
  background: #3ec9d6;
  color: #fff;
  border-color: #3ec9d6;
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-check:active + .btn-light-primary,
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-check:checked + .btn-light-primary {
  background: #3ec9d6;
  color: #fff;
  border-color: #3ec9d6;
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary {
  background: transparent;
  color: #3ec9d6;
  border-color: transparent;
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary .material-icons-two-tone {
  background-color: #3ec9d6;
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary:hover {
  background: rgba(62, 201, 214, 0.2);
  color: #3ec9d6;
  border-color: rgba(62, 201, 214, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary.focus, [data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary:focus {
  background: rgba(62, 201, 214, 0.2);
  color: #3ec9d6;
  border-color: rgba(62, 201, 214, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary:not(:disabled):not(.disabled).active, [data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary:not(:disabled):not(.disabled):active, .show > [data-pc-theme=dark][data-pc-preset=preset-10] .btn-link-primary.dropdown-toggle {
  background: rgba(62, 201, 214, 0.2);
  color: #3ec9d6;
  border-color: rgba(62, 201, 214, 0.2);
}
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-check:active + .btn-link-primary,
[data-pc-theme=dark][data-pc-preset=preset-10] .btn-check:checked + .btn-link-primary {
  background: rgba(62, 201, 214, 0.2);
  color: #3ec9d6;
  border-color: rgba(62, 201, 214, 0.2);
}
[data-pc-theme=dark] .badge.bg-light-dark,
[data-pc-theme=dark] .badge.bg-light.text-dark {
  color: var(--bs-body-color) !important;
}
[data-pc-theme=dark] .card .bg-light,
[data-pc-theme=dark] .card .card-footer.bg-light {
  background-color: rgba(0, 0, 0, 0.15) !important;
}
[data-pc-theme=dark] .introjs-tooltip,
[data-pc-theme=dark] .notifier,
[data-pc-theme=dark] .datepicker-picker {
  background-color: #1d2630;
}
[data-pc-theme=dark] .datepicker-cell.focused:not(.selected), [data-pc-theme=dark] .datepicker-cell:not(.disabled):hover {
  background: var(--bs-primary);
}
[data-pc-theme=dark] .datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
  background-color: rgba(var(--bs-primary), 0.3);
}
[data-pc-theme=dark] .datepicker-cell.range {
  background: #1d2630;
}
[data-pc-theme=dark] .datepicker-controls .btn {
  background: transparent;
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .i-main .i-block {
  border-color: rgba(0, 0, 0, 0.3);
}
[data-pc-theme=dark] .material-icons-two-tone:not([class*=text]) {
  background-color: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-calendar {
  background: var(--bs-body-bg);
  box-shadow: none;
}
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-month,
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-months .flatpickr-prev-month,
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-months .flatpickr-next-month {
  color: var(--bs-body-color);
  fill: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-calendar span.flatpickr-weekday {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-day {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.flatpickr-disabled, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.flatpickr-disabled:hover, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.prevMonthDay, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.nextMonthDay, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.notAllowed, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.notAllowed.prevMonthDay, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.notAllowed.nextMonthDay {
  color: var(--bs-body-color);
  opacity: 0.3;
}
[data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.prevMonthDay.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.nextMonthDay.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.today.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.prevMonthDay.today.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.nextMonthDay.today.inRange, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day:hover, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.prevMonthDay:hover, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.nextMonthDay:hover, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day:focus, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.prevMonthDay:focus, [data-pc-theme=dark] .flatpickr-calendar .flatpickr-day.nextMonthDay:focus {
  background: #303f50;
  border-color: #303f50;
  box-shadow: -5px 0 0 #303f50, 5px 0 0 #303f50;
}
[data-pc-theme=dark] .flatpickr-calendar.arrowTop:after, [data-pc-theme=dark] .flatpickr-calendar.arrowTop:before {
  border-bottom-color: var(--bs-body-bg);
}
[data-pc-theme=dark] .flatpickr-calendar.arrowBottom:after, [data-pc-theme=dark] .flatpickr-calendar.arrowBottom:before {
  border-top-color: var(--bs-body-bg);
}
[data-pc-theme=dark] .flatpickr-calendar.hasTime .flatpickr-time {
  border-top: none;
}
[data-pc-theme=dark] .flatpickr-time input,
[data-pc-theme=dark] .flatpickr-time .flatpickr-am-pm {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-time input:focus, [data-pc-theme=dark] .flatpickr-time input:hover,
[data-pc-theme=dark] .flatpickr-time .flatpickr-am-pm:focus,
[data-pc-theme=dark] .flatpickr-time .flatpickr-am-pm:hover {
  background: #303f50;
}
[data-pc-theme=dark] .flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: var(--bs-body-color);
}
[data-pc-theme=dark] .flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: var(--bs-body-color);
}
[data-pc-theme=dark] .choices__inner {
  border-color: rgba(255, 255, 255, 0.15);
  background: transparent;
}
[data-pc-theme=dark] .choices__inner .choices__input {
  background: transparent;
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .choices[data-type*=select-one]:after {
  border-color: var(--bs-body-color) transparent transparent transparent;
}
[data-pc-theme=dark] .choices[data-type*=select-one] .choices__input {
  background-color: transparent;
  border-bottom: none;
}
[data-pc-theme=dark] .choices.is-disabled .choices__inner,
[data-pc-theme=dark] .choices.is-disabled .choices__input {
  background: transparent;
}
[data-pc-theme=dark] .choices__list--dropdown {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] .noUi-target {
  box-shadow: none;
  background: rgba(0, 0, 0, 0.1);
  border-color: rgba(255, 255, 255, 0.15);
}
[data-pc-theme=dark] .noUi-handle {
  background: var(--bs-body-bg);
  border-color: rgba(255, 255, 255, 0.15);
  box-shadow: none;
}
[data-pc-theme=dark] .noUi-handle:after, [data-pc-theme=dark] .noUi-handle:before {
  background: var(--bs-body-color);
}
[data-pc-theme=dark] .noUi-tooltip {
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .typeahead > ul {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] .ql-container.ql-snow,
[data-pc-theme=dark] .ql-toolbar.ql-snow {
  border-color: #303f50;
}
[data-pc-theme=dark] .ql-snow .ql-picker {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .ql-snow .ql-stroke {
  stroke: var(--bs-body-color);
}
[data-pc-theme=dark] .ck {
  --ck-color-base-background: var(--bs-body-bg);
  --ck-color-toolbar-background: var(--bs-body-bg);
  --ck-color-base-border: #303f50;
  --ck-color-toolbar-border: #303f50;
  --ck-color-dropdown-panel-border: rgba(0, 0, 0, 0.5);
  --ck-color-button-default-background: transparent;
  --ck-color-text: var(--bs-body-color);
  --ck-color-list-background: var(--bs-body-bg);
  --ck-color-button-default-hover-background: #303f50;
  --ck-color-button-default-active-background: #303f50;
  --ck-color-button-on-active-background: #303f50;
  --ck-color-button-on-background: #303f50;
  --ck-color-button-on-hover-background: #303f50;
  --ck-color-list-button-hover-background: #303f50;
  --ck-color-dropdown-panel-background: var(--bs-body-bg);
  --ck-color-input-background: var(--bs-body-bg);
  --ck-color-panel-background: var(--bs-body-bg);
  --ck-color-panel-border: #303f50;
}
[data-pc-theme=dark] .ck.ck-editor__editable.ck-editor__editable_inline {
  background: var(--bs-body-bg) !important;
  border-color: #303f50;
}
[data-pc-theme=dark] #cke5-inline-demo .ck-content {
  border-color: #303f50;
}
[data-pc-theme=dark] .editor-toolbar,
[data-pc-theme=dark] .CodeMirror {
  background: var(--bs-body-bg);
  color: var(--bs-body-color);
  border-color: #303f50;
}
[data-pc-theme=dark] .editor-toolbar i.separator {
  border-left-color: #303f50;
  border-right-color: #303f50;
}
[data-pc-theme=dark] .editor-toolbar a {
  color: var(--bs-body-color) !important;
}
[data-pc-theme=dark] .editor-toolbar a.active, [data-pc-theme=dark] .editor-toolbar a:hover {
  background: #303f50;
  border-color: #303f50;
}
[data-pc-theme=dark] .dropzone {
  background: #303f50;
}
[data-pc-theme=dark] .uppy-Dashboard-inner,
[data-pc-theme=dark] .uppy-DragDrop-container {
  background: #303f50;
  border-color: #303f50;
}
[data-pc-theme=dark] .uppy-DashboardTab-btn:hover {
  background: #303f50;
}
[data-pc-theme=dark] .uppy-DashboardTab-btn,
[data-pc-theme=dark] .uppy-Dashboard-AddFiles-title {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] [data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
  border-color: #303f50;
}
[data-pc-theme=dark] .uppy-StatusBar,
[data-pc-theme=dark] .uppy-DashboardContent-bar {
  border: none;
  color: var(--bs-body-color);
  background: #303f50;
}
[data-pc-theme=dark] .table {
  --bs-table-color: var(--bs-body-color);
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-border-color: #303f50;
}
[data-pc-theme=dark] .table thead th {
  color: var(--bs-body-color);
  background: #303f50;
  border-color: #303f50;
}
[data-pc-theme=dark] .table > :not(:last-child) > :last-child > *,
[data-pc-theme=dark] .table td,
[data-pc-theme=dark] .table th {
  border-color: #303f50;
}
[data-pc-theme=dark] .table > :not(caption) > * > * {
  background-color: transparent;
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: transparent;
}
[data-pc-theme=dark] .dataTable-pagination a {
  color: var(--bs-body-color);
}
[data-pc-theme=dark] .dataTable-pagination a:hover {
  background: #303f50;
}
[data-pc-theme=dark] .dataTable-pagination .active a {
  color: #fff;
}
[data-pc-theme=dark] .dataTable-sorter::after {
  border-bottom-color: var(--bs-body-color);
}
[data-pc-theme=dark] .dataTable-sorter::before {
  border-top-color: var(--bs-body-color);
}
[data-pc-theme=dark] table.dataTable.fixedHeader-floating,
[data-pc-theme=dark] table.dataTable.fixedHeader-locked,
[data-pc-theme=dark] div.DTFC_LeftHeadWrapper table,
[data-pc-theme=dark] div.DTFC_RightHeadWrapper table,
[data-pc-theme=dark] table.DTFC_Cloned tr {
  background: var(--bs-body-bg);
}
[data-pc-theme=dark] .table.dataTable[class*=table-] thead th {
  background: #303f50;
}
[data-pc-theme=dark] .table-bordered > :not(caption) > * {
  border-width: 0px;
}
[data-pc-theme=dark] table.dataTable > tbody > tr.child ul.dtr-details > li {
  border-bottom-color: #303f50;
}
[data-pc-theme=dark] .apexcharts-legend-text {
  color: var(--bs-body-color) !important;
}
[data-pc-theme=dark] text {
  fill: var(--bs-body-color) !important;
}
[data-pc-theme=dark] .apexcharts-canvas line {
  stroke: transparent !important;
}
[data-pc-theme=dark] .fc .fc-list-sticky .fc-list-day > *,
[data-pc-theme=dark] .fc .fc-scrollgrid-section-sticky > *,
[data-pc-theme=dark] .auth-main .auth-wrapper.v3,
[data-pc-theme=dark] .auth-main .auth-wrapper.v2 {
  background: #1b232d;
}
[data-pc-theme=dark] .table-bordered td,
[data-pc-theme=dark] .table-bordered th,
[data-pc-theme=dark] .table-bordered {
  border-color: #303f50;
}
[data-pc-theme=dark] div.DTFC_Blocker {
  background: transparent;
}
[data-pc-theme=dark] .contact-form.bg-white {
  background: #1b232d !important;
}
[data-pc-theme=dark] .fc-event.event-theme {
  background: rgba(255, 51, 102, 0.2) !important;
  color: #ff3366 !important;
}
[data-pc-theme=dark] .fc-event.event-theme.fc-h-event .fc-event-main {
  color: #ff3366;
}
[data-pc-theme=dark] .fc-event.event-theme.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-theme.fc-h-event:hover {
  background: #ff3366;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-primary {
  background: rgba(13, 110, 253, 0.2) !important;
  color: #0d6efd !important;
}
[data-pc-theme=dark] .fc-event.event-primary.fc-h-event .fc-event-main {
  color: #0d6efd;
}
[data-pc-theme=dark] .fc-event.event-primary.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-primary.fc-h-event:hover {
  background: #0d6efd;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-secondary {
  background: rgba(91, 107, 121, 0.2) !important;
  color: #5b6b79 !important;
}
[data-pc-theme=dark] .fc-event.event-secondary.fc-h-event .fc-event-main {
  color: #5b6b79;
}
[data-pc-theme=dark] .fc-event.event-secondary.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-secondary.fc-h-event:hover {
  background: #5b6b79;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-success {
  background: rgba(25, 135, 84, 0.2) !important;
  color: #198754 !important;
}
[data-pc-theme=dark] .fc-event.event-success.fc-h-event .fc-event-main {
  color: #198754;
}
[data-pc-theme=dark] .fc-event.event-success.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-success.fc-h-event:hover {
  background: #198754;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-info {
  background: rgba(13, 202, 240, 0.2) !important;
  color: #0dcaf0 !important;
}
[data-pc-theme=dark] .fc-event.event-info.fc-h-event .fc-event-main {
  color: #0dcaf0;
}
[data-pc-theme=dark] .fc-event.event-info.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-info.fc-h-event:hover {
  background: #0dcaf0;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-warning {
  background: rgba(255, 193, 7, 0.2) !important;
  color: #ffc107 !important;
}
[data-pc-theme=dark] .fc-event.event-warning.fc-h-event .fc-event-main {
  color: #ffc107;
}
[data-pc-theme=dark] .fc-event.event-warning.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-warning.fc-h-event:hover {
  background: #ffc107;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-danger {
  background: rgba(220, 53, 69, 0.2) !important;
  color: #dc3545 !important;
}
[data-pc-theme=dark] .fc-event.event-danger.fc-h-event .fc-event-main {
  color: #dc3545;
}
[data-pc-theme=dark] .fc-event.event-danger.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-danger.fc-h-event:hover {
  background: #dc3545;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-light {
  background: rgba(248, 249, 250, 0.2) !important;
  color: #f8f9fa !important;
}
[data-pc-theme=dark] .fc-event.event-light.fc-h-event .fc-event-main {
  color: #f8f9fa;
}
[data-pc-theme=dark] .fc-event.event-light.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-light.fc-h-event:hover {
  background: #f8f9fa;
  color: #fff;
}
[data-pc-theme=dark] .fc-event.event-dark {
  background: rgba(19, 25, 32, 0.2) !important;
  color: #131920 !important;
}
[data-pc-theme=dark] .fc-event.event-dark.fc-h-event .fc-event-main {
  color: #131920;
}
[data-pc-theme=dark] .fc-event.event-dark.fc-h-event:focus, [data-pc-theme=dark] .fc-event.event-dark.fc-h-event:hover {
  background: #131920;
  color: #fff;
}
[data-pc-theme=dark] .auth-main .auth-wrapper.v1 .auth-form {
  background-image: url("../images/authentication/img-auth-bg-dark.jpg") !important;
}